define('ownersup-client/components/comment-new/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var inject = _ember['default'].inject;
  exports['default'] = Component.extend({
    currentUser: inject.service('current-user'),

    classNames: ['comment-new'],
    actions: {
      newComment: function newComment() {
        this.sendAction();
      }
    }
  });
});