define('ownersup-client/goals/index/route', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  var RSVP = _ember['default'].RSVP;
  var inject = _ember['default'].inject;
  exports['default'] = Route.extend({
    currentUser: inject.service('current-user'),

    model: function model() {
      return RSVP.hash({
        activeGoals: this.store.query('goal', {
          status: 'active',
          group_membership_ids: this.get('currentUser.groupMembershipIds')
        })
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties(model);

      controller.set('model.completedGoals', this.store.query('goal', {
        status: 'completed',
        group_membership_ids: this.get('currentUser.groupMembershipIds')
      }));
    }
  });
});