define('ownersup-client/components/card-commentable/component', ['exports', 'ember', 'ownersup-client/mixins/component-attribute-types'], function (exports, _ember, _ownersupClientMixinsComponentAttributeTypes) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;
  exports['default'] = Component.extend(_ownersupClientMixinsComponentAttributeTypes['default'], {
    currentUser: inject.service('current-user'),
    flashMessages: inject.service(),

    classNames: ['card'],
    classNameBindings: ['cardType', 'cardDisplayState'],

    isEditingComment: false,
    showComments: false,

    cardType: computed('commentable', function () {
      return this.get('commentable.constructor.modelName') + '-item';
    }),
    cardDisplayState: computed.readOnly('commentable.displayState'),
    groupId: computed.oneWay('commentable.groupMembership.group.id'),

    currentGroupMembership: computed.filter('currentUser.user.groupMemberships', function (groupMembership) {
      return groupMembership.get('group.id') === this.get('groupId');
    }),

    attrTypes: {
      save: true,
      cancel: true,
      newComment: true,
      commentable: true
    },

    actions: {
      submit: function submit(commentable, path) {
        this.sendAction('save', commentable, path);
      },

      cancel: function cancel(commentable, path) {
        this.set('isEditingComment', false);
        this.sendAction('cancel', commentable, path);
      },

      isEditing: function isEditing(value) {
        this.set('isEditingComment', value);
      },

      newComment: function newComment() {
        this.setProperties({
          isEditingComment: true,
          showComments: true
        });
        this._createComment();
      },

      showComments: function showComments() {
        this.set('showComments', true);
      },

      saveComment: function saveComment(comment) {
        var _this = this;

        var flashMessages = get(this, 'flashMessages');

        comment.save().then(function () {
          _this.set('isEditingComment', false);
          flashMessages.success('Comment saved');
        })['catch'](function (err) {
          flashMessages.danger(err.message);
        });
      },

      deleteComment: function deleteComment(comment) {
        var flashMessages = get(this, 'flashMessages');

        comment.destroyRecord().then(function () {
          flashMessages.success('Comment deleted');
        })['catch'](function (err) {
          flashMessages.danger(err.message);
        });
      }
    },

    _createComment: function _createComment() {
      this.sendAction('newComment', {
        groupMembership: this.get('currentGroupMembership').objectAt(0),
        commentable: this.get('commentable'),
        isEditing: true
      });
    }
  });
});