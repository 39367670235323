define('ownersup-client/goals/new/route', ['exports', 'ember', 'ownersup-client/mixins/group-membership-content-route', 'ownersup-client/mixins/reset-scroll'], function (exports, _ember, _ownersupClientMixinsGroupMembershipContentRoute, _ownersupClientMixinsResetScroll) {
  var Route = _ember['default'].Route;
  var RSVP = _ember['default'].RSVP;
  var inject = _ember['default'].inject;
  exports['default'] = Route.extend(_ownersupClientMixinsGroupMembershipContentRoute['default'], _ownersupClientMixinsResetScroll['default'], {
    currentUser: inject.service('current-user'),

    model: function model() {
      return RSVP.hash({
        newGoal: this.store.createRecord('goal'),
        activeGoals: this.store.query('goal', {
          group_membership_id: [this.get('currentUser.activeGroupMembershipId')],
          status: 'active'
        })
      });
    },

    afterModel: function afterModel(model) {
      this._setGroupMembership(model.newGoal);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('editedModel', 'model.newGoal');
    }
  });
});