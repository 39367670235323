define('ownersup-client/application/controller', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var Controller = _ember['default'].Controller;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var inject = _ember['default'].inject;
  exports['default'] = Controller.extend({
    session: inject.service(),
    currentUser: inject.service(),
    flashMessages: inject.service(),

    actions: {
      processStripeToken: function processStripeToken(token) {
        this.setProperties({ token: token });

        this._showModal('modal-current-password', this);
      },

      showModal: function showModal(modalDialogName, modalContext) {
        this._showModal(modalDialogName, modalContext);
      },

      closeModal: function closeModal() {
        if (get(this, 'modalDialogName') !== 'modals/modal-trial-expired') {
          set(this, 'isModalVisible', false);
        }
      }
    },

    _showModal: function _showModal(modalDialogName, modalContext) {
      this.setProperties({
        modalDialogName: modalDialogName,
        modalContext: modalContext,
        isModalVisible: true
      });
    },

    _verifyUserStatus: function _verifyUserStatus() {
      var user = this.get('currentUser.user');
      if (!user.get('isCreditCardOnFile')) {
        if (user.get('subscription.isTrialEndingSoon')) {
          get(this, 'flashMessages').add({
            message: 'Your free trial ends on ' + (0, _moment['default'])(user.get('subscription.trialEnd')).format('MMM Do') + '. Please add a credit card before that date!',
            sticky: true,
            type: 'info',
            componentName: 'flash-trial-ending'
          });
        }

        if (user.get('subscription.hasTrialExpired')) {
          this._showModal('modals/modal-trial-expired', this);
        }
      }
    }

  });
});