define('ownersup-client/components/empty-message/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNameBindings: ['data-test-id'],
    attributeBindings: ['data-test-id'],

    tagName: 'p',

    isLoading: computed('model.isLoading', function () {
      return !!this.get('model') && this.get('model.isLoading');
    })
  });
});