define('ownersup-client/components/comment-count/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'span',
    classNames: ['comment-count', 'with-icon'],
    inflectedCount: computed('count', function () {
      var initCount = this.get('count');
      var count = initCount === 0 ? 'No' : initCount;
      var description = initCount === 1 ? 'Comment' : 'Comments';

      return count + ' ' + description;
    })
  });
});