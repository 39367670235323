define('ownersup-client/group/route', ['exports', 'ember', 'ownersup-client/mixins/reset-scroll', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _ownersupClientMixinsResetScroll, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var Route = _ember['default'].Route;
  var inject = _ember['default'].inject;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _ownersupClientMixinsResetScroll['default'], {
    session: inject.service('session'),
    currentUser: inject.service(),
    model: function model() {
      return RSVP.hash({
        group: this.store.findRecord('group', this.get('currentUser.activeGroup.id'))
      });
    }
  });
});