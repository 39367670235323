define('ownersup-client/user/model', ['exports', 'ember', 'ember-data', 'ownersup-client/mixins/timestampable', 'ownersup-client/mixins/authorizable', 'ownersup-client/mixins/non-rest-action'], function (exports, _ember, _emberData, _ownersupClientMixinsTimestampable, _ownersupClientMixinsAuthorizable, _ownersupClientMixinsNonRestAction) {
  var computed = _ember['default'].computed;
  var _get = _ember['default'].get;
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend(_ownersupClientMixinsTimestampable['default'], _ownersupClientMixinsAuthorizable['default'], _ownersupClientMixinsNonRestAction['default'], {
    subscription: belongsTo('subscription', { async: false }),
    isTrialing: computed.oneWay('subscription.inTrial'),

    isUser: computed.equal('role', 'user'),
    isAdmin: computed.equal('role', 'admin'),
    isBillable: computed.oneWay('isUser'),

    firstName: attr('string'),
    lastName: attr('string'),
    fullName: computed('firstName', 'lastName', function () {
      var _getProperties = this.getProperties('firstName', 'lastName');

      var firstName = _getProperties.firstName;
      var lastName = _getProperties.lastName;

      return firstName + ' ' + lastName;
    }),

    email: attr('string'),
    role: attr('string', { defaultValue: 'user' }),
    companyName: attr('string'),
    companyWebsite: attr('string'),
    bio: attr('string'),
    twitterUsername: attr('string'),
    facebookUsername: attr('string'),
    instagramUsername: attr('string'),
    linkedInUrl: attr('string'),
    birthday: attr('date'),

    timeZone: attr('string'),

    confirmedAt: attr('date'),
    isConfirmed: attr('boolean'),

    agreed: attr('boolean'), // Terms and Privacy

    invitationToken: attr('string'),
    invitedToSignUp: attr('boolean'),
    isInvited: computed.oneWay('invitedToSignUp'),
    invitationSentAt: attr('date'),

    notificationNewsletter: attr('boolean'),

    currentPassword: attr('string'),
    password: attr('string'),
    passwordConfirmation: attr('string'),
    stripeCustomerId: attr('string'),
    stripeAccessToken: attr('string'),
    stripePlanId: attr('string'),
    stripeCardLast4: attr('string'),
    stripeCardBrand: attr('string'),
    stripeCardFunding: attr('string'),
    stripeCardExpMonth: attr('string'),
    stripeCardExpYear: attr('string'),
    subscriptionId: attr('string'),

    token: computed({
      set: function set(key, token) {
        return this.setProperties({
          stripeAccessToken: token.id,
          stripeCardLast4: token.card.last4,
          stripeCardBrand: token.card.brand,
          stripeCardFunding: token.card.funding,
          stripeCardExpMonth: token.card.exp_month,
          stripeCardExpYear: token.card.exp_year
        });
      },
      get: function get() {
        return _get(this, 'stripeAccessToken');
      }
    }),

    isCreditCardOnFile: computed.notEmpty('stripeCardLast4'),

    groupMemberships: hasMany('groupMemberships', {
      inverse: 'user',
      async: false
    }),
    groupMembershipIds: computed.mapBy('groupMemberships', 'id'),
    groupMembershipsCount: computed.readOnly('groupMemberships.length'),
    groups: computed.mapBy('groupMemberships', 'group'),

    // Actions & Events

    didCreate: function didCreate() {
      this._clearSensativeData();
    },

    didUpdate: function didUpdate() {
      this._clearSensativeData();
    },

    // Private

    _clearSensativeData: function _clearSensativeData() {
      this.setProperties({
        password: '',
        currentPassword: null,
        passwordConfirmation: null,
        stripeAccessToken: null
      });
    }
  });
});