define('ownersup-client/components/reports-summary/component', ['exports', 'ember', 'ownersup-client/components/chart-base/component', 'ownersup-client/config/environment', 'moment'], function (exports, _ember, _ownersupClientComponentsChartBaseComponent, _ownersupClientConfigEnvironment, _moment) {
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;
  exports['default'] = _ownersupClientComponentsChartBaseComponent['default'].extend({
    classNames: ['reports-summary'],
    currentUser: inject.service(),

    init: function init() {
      this._super.apply(this, arguments);

      this.json = this.json || [{ Accomplishment: 0 }];

      this.colors = _ownersupClientConfigEnvironment['default'].APP.categoryColors;
    },

    focusMessages: {
      'Sales': 'Great job!',
      'Marketing': 'Great job!',
      'Well Being': 'Great job!',
      'Retention': 'It\'s important to keep your existing customers happy. Great work. Don\'t forget about your pipeline though!',
      'Systems': 'Great work. Make sure to take some time to validate that your systems are increasing your time and revenue',
      'Partnerships': 'Make sure to take some time to validate that your partnerships are actually increasing your revenue',
      'Other': 'What\'s up? I thought you cared about your business'
    },

    dataFor: 7,
    sevenActive: computed.equal('dataFor', 7),
    thirtyActive: computed.equal('dataFor', 30),

    isEmpty: computed.lt('json.length', 1),
    categoryNames: computed.mapBy('categories', 'name'),

    // summary: computed.oneWay('groupMembership.reportsSummary'),
    data: computed('json', function () {
      var colors = this.get('colors');
      return {
        json: this.get('json'),
        keys: {
          value: this.get('categoryNames')
        },
        type: 'donut',
        colors: colors
      };
    }),

    legend: {
      show: false
    },

    // totalAccomplishments: computed.readOnly('json.firstObject.Accomplishment'),
    totalAccomplishments: computed('json.@each.Accomplishment', function () {
      return this.get('json').reduce(function (previousValue, item) {
        return previousValue + item.Accomplishment;
      }, 0);
    }),
    legendData: computed('json.[]', function () {
      var _getProperties = this.getProperties('json', 'categoryNames', 'totalAccomplishments');

      var json = _getProperties.json;
      var categoryNames = _getProperties.categoryNames;
      var totalAccomplishments = _getProperties.totalAccomplishments;

      var sums = {};
      var categories = [];

      json.forEach(function (month) {
        for (var category in month) {
          if (categoryNames.indexOf(category) !== -1) {
            if (!sums[category]) {
              sums[category] = 0;
            }

            sums[category] = sums[category] + month[category];
          }
        }
      });

      for (var category in sums) {
        categories.push({
          name: category,
          value: sums[category],
          percentage: Math.round(sums[category] / totalAccomplishments * 100)
        });
      }
      return categories;
    }),

    categorySort: ['value:desc'],
    sortedCategories: computed.sort('legendData', 'categorySort'),
    topCategory: computed.oneWay('sortedCategories.firstObject'),
    topCategoryHelpText: computed('topCategory', function () {
      return this.get('focusMessages')[this.get('topCategory.name')];
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._getReportsSummary();
    },

    actions: {
      dataFor: function dataFor(days) {
        this.set('dataFor', days);
        this._getReportsSummary();
      }
    },

    _getReportsSummary: function _getReportsSummary() {
      var _this = this;

      var groupMembership = get(this, 'currentUser.activeGroupMembership');
      var days = this.get('dataFor');
      var from = (0, _moment['default'])().subtract(days, 'days').format('YYYY-MM-DD');

      if (!groupMembership || !groupMembership.customAction) {
        return;
      }

      groupMembership.customAction('stats/reports?from=' + from + '&groupBy=month').then(function (response) {
        _this.set('json', response.stats.report_stats);
      });
    }
  });
});