define('ownersup-client/users/password/edit/route', ['exports', 'ember', 'ownersup-client/config/environment', 'ownersup-client/utils/format-error-json'], function (exports, _ember, _ownersupClientConfigEnvironment, _ownersupClientUtilsFormatErrorJson) {
  var Route = _ember['default'].Route;
  var get = _ember['default'].get;
  var $ = _ember['default'].$;
  var run = _ember['default'].run;
  var inject = _ember['default'].inject;

  var url = _ownersupClientConfigEnvironment['default'].APP.API_HOST + '/v1/users/password';

  exports['default'] = Route.extend({
    flashMessages: inject.service(),

    beforeModel: function beforeModel(transition) {
      var flashMessages = get(this, 'flashMessages');
      var token = transition.queryParams.reset_password_token;

      if (!token) {
        flashMessages.danger('The reset password token is invalid or not present. Please request a new token');
        return this.transitionTo('/users/password/new');
      }
    },

    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        var flashMessages = get(this, 'flashMessages');
        var properties = this.controller.getProperties('reset_password_token', 'password', 'password_confirmation');
        var data = { api_v1_user: properties };

        $.ajax({
          type: 'PUT',
          url: url,
          data: data
        }).then(function () {
          run(function () {
            flashMessages.success('Success! Now take that new password for a spin and try logging in');
            return _this.transitionTo('/login');
          });
        }, function (error) {
          run(function () {
            flashMessages.danger((0, _ownersupClientUtilsFormatErrorJson['default'])(error));
          });
        });
      }
    }
  });
});