define('ownersup-client/messages/index/route', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  var RSVP = _ember['default'].RSVP;
  var inject = _ember['default'].inject;
  exports['default'] = Route.extend({
    currentUser: inject.service('current-user'),

    model: function model() {
      return RSVP.hash({
        requests: this.store.query('message', {
          messageType: 'Request for Feedback',
          group_membership_ids: this.get('currentUser.groupMembershipIds')
        })
      });
    }
  });
});