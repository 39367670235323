define('ownersup-client/account/billing/route', ['exports', 'ember', 'ownersup-client/mixins/flash-messageable'], function (exports, _ember, _ownersupClientMixinsFlashMessageable) {
  var get = _ember['default'].get;
  var Route = _ember['default'].Route;
  var inject = _ember['default'].inject;
  exports['default'] = Route.extend(_ownersupClientMixinsFlashMessageable['default'], {
    currentUser: inject.service(),
    flashMessages: inject.service(),

    model: function model() {
      return this.get('currentUser.user');
    },

    actions: {
      processStripeToken: function processStripeToken(token) {
        this.controller.setProperties({
          token: token,
          askForPassword: true
        });
      },

      confirmCardUpdate: function confirmCardUpdate() {
        var _this = this;

        var flash = get(this, 'flashMessages');
        var user = this.get('controller.model');

        var _controller$getProperties = this.controller.getProperties('token', 'currentPassword');

        var token = _controller$getProperties.token;
        var currentPassword = _controller$getProperties.currentPassword;

        if (!token) {
          flash.danger('Looks like something broke. Try entering your card info again');
          this.controller.set('askForPassword', false);
        }

        if (!currentPassword) {
          flash.danger('Hey there. Make sure you fill out your current password');
          return false;
        }

        user.setProperties({ currentPassword: currentPassword, token: token });
        user.save().then(function () {
          user.reload();
          _this.controller.set('askForPassword', false);
          flash.success('Sweet! Your credit card has been updated');
        }, function (err) {
          _this._displayErrors(err);
        });
      }
    }
  });
});