define('ownersup-client/message/model', ['exports', 'ember-data', 'ownersup-client/commentable/model', 'ownersup-client/mixins/group-membershipable'], function (exports, _emberData, _ownersupClientCommentableModel, _ownersupClientMixinsGroupMembershipable) {
  var attr = _emberData['default'].attr;

  // const messageTypes = ['Request for Feedback'];

  exports['default'] = _ownersupClientCommentableModel['default'].extend(_ownersupClientMixinsGroupMembershipable['default'], {
    title: attr('string'),
    body: attr('string')
    // messageTypes,
    // messageType: attr('string', { defaultValue: messageTypes[0] })
  });
});