define('ownersup-client/components/report-form/component', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var run = _ember['default'].run;
  var get = _ember['default'].get;
  var getProperties = _ember['default'].getProperties;
  var set = _ember['default'].set;
  var Component = _ember['default'].Component;
  var inject = _ember['default'].inject;
  exports['default'] = Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.maxDate = new Date();
    },
    currentUser: inject.service(),
    flashMessages: inject.service(),

    classNames: ['report-form'],
    actions: {
      newActivity: function newActivity(type) {
        this._newActivity(type);
      },

      handleDateChange: function handleDateChange(date) {
        this._updateReportDate(date);
      },

      submit: function submit() {
        var report = get(this, 'report');

        if (this._validReport()) {
          this.sendAction('save', report, false);
        }
      },

      publish: function publish() {
        set(this, 'report.status', 'published');
      },

      cancel: function cancel() {
        var report = get(this, 'report');

        this.sendAction('cancel', report);
      },

      'delete': function _delete() {
        var report = get(this, 'report');

        this.sendAction('delete', report);
      },

      deleteActivity: function deleteActivity(activity) {
        var flashMessages = get(this, 'flashMessages');

        if (activity.get('isNew')) {
          activity.deleteRecord();
        } else {
          activity.destroyRecord().then(function () {
            flashMessages.success('WrapUp activity has been deleted');
          })['catch'](function (err) {
            flashMessages.danger(err.responseText);
          });
        }
      }
    },

    _updateReportDate: function _updateReportDate(date) {
      set(this, 'report.createdAt', date);
      get(this, 'report.reportActivities').setEach('createdAt', date);
    },

    _newActivity: function _newActivity(type) {
      var report = get(this, 'report');

      var _getProperties = getProperties(report, 'groupMembership', 'createdAt', 'reportActivities');

      var groupMembership = _getProperties.groupMembership;
      var createdAt = _getProperties.createdAt;
      var reportActivities = _getProperties.reportActivities;

      this.type = type;

      createdAt = (0, _moment['default'])(createdAt).add(get(reportActivities, 'length'), 's').toDate();

      reportActivities.createRecord({ type: type, groupMembership: groupMembership, createdAt: createdAt });

      function _focusTextarea() {
        this.$('.report-' + this.type + ':last textarea').focus();
      }

      run.scheduleOnce('afterRender', this, _focusTextarea);
    },

    _validReport: function _validReport() {
      var flashMessages = get(this, 'flashMessages');

      var accomplishments = get(this, 'report.accomplishments');

      if (!get(this, 'report.groupMembership.id')) {
        flashMessages.danger('The groupMembership doesn\'t appear to be set. Please contact support');
        return false;
      }

      if (accomplishments.length === 0 || !accomplishments.get('firstObject.notEmpty')) {
        flashMessages.danger('You need to have at least one accomplishment');

        return false;
      }

      if (!accomplishments.isEvery('hasCategory')) {
        flashMessages.danger('Every accomplishment needs a category');
        return false;
      }

      return true;
    }

  });
});