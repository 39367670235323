define('ownersup-client/group-membership/model', ['exports', 'ember', 'ember-data', 'moment', 'ownersup-client/mixins/timestampable', 'ownersup-client/mixins/authorizable', 'ownersup-client/mixins/non-rest-action'], function (exports, _ember, _emberData, _moment, _ownersupClientMixinsTimestampable, _ownersupClientMixinsAuthorizable, _ownersupClientMixinsNonRestAction) {
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend(_ownersupClientMixinsTimestampable['default'], _ownersupClientMixinsAuthorizable['default'], _ownersupClientMixinsNonRestAction['default'], {
    status: attr('string', { defaultValue: 'active' }),
    isActive: computed('status', 'isConfirmed', function () {
      return this.get('status') === 'active' && !!this.get('isConfirmed');
    }),
    role: attr('string', { defaultValue: 'member' }),
    monthlyGoalAmount: attr('number'),
    monthlyGoalUpdated: attr('date'),

    reportsCount: attr('number'),

    user: belongsTo('user', { async: true, inverse: 'groupMemberships' }),
    isConfirmed: computed.readOnly('user.isConfirmed'),
    isInvited: computed.readOnly('user.isInvited'),

    group: belongsTo('group', { async: true, inverse: 'groupMemberships' }),

    reports: hasMany('report', { async: true, inverse: 'groupMembership' }),
    tallyups: hasMany('tallyup', { async: true, inverse: 'groupMembership' }),

    timeZone: computed.readOnly('user.timeZone'),
    notificationReportReminder: attr('boolean', { defaultValue: true }),
    notificationReportReminderTime: attr('utc-time'),
    notificationReportReminderTimeInZone: computed('notificationReportReminderTime', function () {
      return this._timeToUserZone('notificationReportReminderTime');
    }),

    notificationGoalReminder: attr('boolean', { defaultValue: true }),
    notificationTallyupReminder: attr('boolean', { defaultValue: true }),
    notificationTallyupReminderTime: attr('utc-time'),
    notificationTallyupReminderTimeInZone: computed('notificationTallyupReminderTime', function () {
      return this._timeToUserZone('notificationTallyupReminderTime');
    }),

    notificationComments: attr('boolean', { defaultValue: true }),
    notificationNewContent: attr('boolean', { defaultValue: true }),
    notificationWeeklyReport: attr('boolean', { defaultValue: true }),
    notificationWeeklyReportTime: attr('utc-time'),
    notificationWeeklyReportTimeInZone: computed('notificationWeeklyReportTime', function () {
      return this._timeToUserZone('notificationWeeklyReportTime');
    }),

    goals: hasMany('goal', { async: true }),
    goalsCount: computed.oneWay('goals.length'),
    activeGoals: computed.filterBy('goals', 'status', 'active'),
    activeGoalCount: computed.oneWay('activeGoals.length'),

    archive: function archive() {
      return this.set('status', 'archived');
    },

    _timeToUserZone: function _timeToUserZone(key) {
      var timeZone = get(this, 'timeZone');
      var reminderTime = get(this, key);

      return (0, _moment['default'])(reminderTime).tz(timeZone).format('h:mm A');
    }
  });
});