define('ownersup-client/components/input-text/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['form-group', 'input-text'],
    classNameBindings: ['labelClass'],
    type: 'text',
    autofocus: false,
    name: false,

    didInsertElement: function didInsertElement() {
      if (this.get('autofocus')) {
        this.$('input').focus();
      }
    },

    testId: computed('label', 'name', function () {
      if (this.get('name')) {
        return this.get('name').dasherize();
      }
      if (this.get('label')) {
        return this.get('label').dasherize();
      }
    }),

    labelClass: computed('label', function () {
      if (this.get('label')) {
        return 'input-' + this.get('label').dasherize();
      }
    }),

    inputId: computed('elementId', function () {
      return 'input-' + this.get('type') + '-' + this.elementId;
    })
  });
});