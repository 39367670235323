define('ownersup-client/progress/reports/controller', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var Controller = _ember['default'].Controller;
  var inject = _ember['default'].inject;
  var run = _ember['default'].run;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var on = _ember['default'].on;
  exports['default'] = Controller.extend({
    queryParams: ['months', 'groupBy'],
    months: 6,
    groupBy: 'month',

    currentUser: inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.chartData = [];
    },

    from: computed('months', function () {
      var subtract = this.get('months') - 1;

      return (0, _moment['default'])().subtract(subtract, 'month').startOf('month').format('YYYY-MM-DD');
    }),

    queryParamsChanged: observer('from', 'groupBy', function () {
      run.once(this, 'chartDataObserver');
    }),

    chartDataObserver: on('init', function () {
      var _this = this;

      var groupMembership = this.get('currentUser.activeGroupMembership');

      var _getProperties = this.getProperties('from', 'groupBy');

      var from = _getProperties.from;
      var groupBy = _getProperties.groupBy;

      return groupMembership.customAction('stats/reports?from=' + from + '&group_by=' + groupBy).then(function (response) {
        _this.set('chartData', response.stats.report_stats);
      });
    })
  });
});