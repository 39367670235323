define('ownersup-client/components/toggle-switch/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: 'label',
    classNames: ['toggle-switch'],
    classNameBindings: ['disabled'],
    attributeBindings: ['key:data-test-id'],
    disabled: false,
    model: false,

    value: computed('model', 'key', {
      get: function get() {
        var key = this.get('key');

        return this.get('model.' + key);
      },

      set: function set(setKey, value) {
        var key = this.get('key');
        this.get('model').set(key, value);

        return value;
      }
    }),

    valueChanged: observer('value', function () {
      var _this = this;

      this.toggleProperty('disabled');
      this.get('model').save().then(function () {
        _this.toggleProperty('disabled');
      });
    })
  });
});