define('ownersup-client/helpers/trimmed-text', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.trimmedText = trimmedText;
  var Helper = _ember['default'].Helper;
  var Handlebars = _ember['default'].Handlebars;

  function trimmedText(_ref) {
    var _ref2 = _slicedToArray(_ref, 1);

    var text = _ref2[0];

    var _ref3 = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

    var count = _ref3.count;

    count = count || 140;

    if (text.length <= count) {
      return text;
    }

    var trimmed = text.substr(0, count + 1);
    var lastSpace = trimmed.lastIndexOf(' ');

    if (lastSpace !== -1) {
      trimmed = trimmed.substr(0, lastSpace);
    }

    return new Handlebars.SafeString(trimmed + '...');
  }

  exports['default'] = Helper.helper(trimmedText);
});