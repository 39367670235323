define('ownersup-client/account/profile/index/route', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    actions: {
      updateForm: function updateForm(values) {
        var model = this.get('controller.model');

        model.setProperties(values);
      }
    }
  });
});