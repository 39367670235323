define('ownersup-client/helpers/big-stat', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.bigStat = bigStat;
  var EmberString = _ember['default'].String;
  var Helper = _ember['default'].Helper;

  function bigStat(_ref) {
    var _ref2 = _slicedToArray(_ref, 2);

    var stat = _ref2[0];
    var label = _ref2[1];

    var id = undefined;
    var attrs = [];
    var html = [];
    var classNames = ['stat'];

    if (label) {
      id = 'stat-' + label.dasherize();
      classNames.push(id);
      attrs.push('data-test-id="' + id + '"');
    }

    attrs.push(['class="' + classNames.join(' ') + '"']);

    html.push('<div ' + attrs.join(' ') + '">');
    html.push(stat);
    if (label) {
      html.push('<small>' + label + '</small>');
    }
    html.push('</div>');

    return EmberString.htmlSafe(html.join(''));
  }

  exports['default'] = Helper.helper(bigStat);
});