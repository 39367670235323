define('ownersup-client/mixins/component-name', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  var assert = _ember['default'].assert;
  var computed = _ember['default'].computed;
  exports['default'] = Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      assert('Component ' + this.toString() + ' requires the attribute \'name\' to be set', this.get('name'));
    },

    classNameBindings: ['name'],
    attributeBindings: ['name']
  });
});