define('ownersup-client/mixins/timestampable', ['exports', 'ember', 'ember-data', 'ember-moment/computeds/format', 'ember-moment/computeds/from-now'], function (exports, _ember, _emberData, _emberMomentComputedsFormat, _emberMomentComputedsFromNow) {
  var Mixin = _ember['default'].Mixin;
  var attr = _emberData['default'].attr;
  exports['default'] = Mixin.create({
    createdAt: attr('date', { defaultValue: function defaultValue() {
        return new Date();
      } }),
    createdAgo: (0, _emberMomentComputedsFromNow['default'])('createdAt', true),
    createdDate: (0, _emberMomentComputedsFormat['default'])('createdAt', 'MM/DD/YYYY'),

    updatedAt: attr('date', { defaultValue: function defaultValue() {
        return new Date();
      } }),
    updatedAgo: (0, _emberMomentComputedsFromNow['default'])('updatedAt', true),
    updatedDate: (0, _emberMomentComputedsFormat['default'])('updatedAt', 'MM/DD/YYYY')
  });
});