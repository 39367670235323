define('ownersup-client/group/index/route', ['exports', 'ember', 'ownersup-client/mixins/reset-scroll'], function (exports, _ember, _ownersupClientMixinsResetScroll) {
  var Route = _ember['default'].Route;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = Route.extend(_ownersupClientMixinsResetScroll['default'], {
    model: function model() {
      return RSVP.hash({
        group: this.modelFor('group').group,
        groupMemberships: this.modelFor('group').groupMemberships
      });
    },

    afterModel: function afterModel() {
      this.transitionTo('group.reports.index');
    },

    queryParams: {
      page: { refreshModel: true },
      page_size: { refreshModel: true }
    }
  });
});