define('ownersup-client/messages/new/route', ['exports', 'ember', 'ownersup-client/mixins/group-membership-content-route', 'ownersup-client/mixins/reset-scroll'], function (exports, _ember, _ownersupClientMixinsGroupMembershipContentRoute, _ownersupClientMixinsResetScroll) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend(_ownersupClientMixinsGroupMembershipContentRoute['default'], _ownersupClientMixinsResetScroll['default'], {
    model: function model() {
      return this.store.createRecord('message');
    },

    afterModel: function afterModel(model) {
      this._setGroupMembership(model);
    }
  });
});