define('ownersup-client/helpers/badge', ['exports', 'ember'], function (exports, _ember) {
  exports.badge = badge;
  var Helper = _ember['default'].Helper;
  var EmberS = _ember['default'].String;

  function badge(content) {
    var _ref = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

    var type = _ref.type;

    var classNames = ['badge', ('badge-' + (type ? type : content)).toLowerCase()];

    var html = '<span class="' + classNames.join(' ') + '">' + content + '</span>';

    return EmberS.htmlSafe(html);
  }

  exports['default'] = Helper.helper(badge);
});