define('ownersup-client/mixins/paginated-controller', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create({
    concatenatedProperties: ['queryParams'],
    queryParams: ['page', 'page_size'],
    page: 1,
    page_size: 10,

    actions: {
      pageChanged: function pageChanged(current) {
        this.set('page', current);
      }
    }
  });
});