define('ownersup-client/tender/service', ['exports', 'ember', 'ember-concurrency', 'ownersup-client/config/environment'], function (exports, _ember, _emberConcurrency, _ownersupClientConfigEnvironment) {
  var Service = _ember['default'].Service;
  var get = _ember['default'].get;
  var $ = _ember['default'].$;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = Service.extend({
    setup: function setup(options) {
      return get(this, 'setupWidget').perform(options);
    },

    setupWidget: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(_ref) {
      var email = _ref.email;
      var date = _ref.date;
      var auth_token = _ref.auth_token;
      var id, history;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            id = _ownersupClientConfigEnvironment['default'].tender.publishableKey;
            context$1$0.next = 3;
            return this.loadTenderWidgetJS();

          case 3:
            history = new window.InvoiceHistory.Widget.History();

            history.identify_customer({ id: id, email: email });
            history.auth_customer({ date: date, auth_token: auth_token });

          case 6:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    loadTenderWidgetJS: function loadTenderWidgetJS() {
      return new RSVP.Promise(function (resolve, reject) {
        $.getScript('https://app.tender.io/Scripts/widgets/history.js').done(function () {
          return resolve();
        }).fail(function (jqxhr, settings, exception) {
          return reject(exception);
        });
      });
    }
  });
});