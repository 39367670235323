define('ownersup-client/components/pop-over/component', ['exports', 'jquery', 'ember'], function (exports, _jquery, _ember) {
  var run = _ember['default'].run;
  var Component = _ember['default'].Component;

  var ESC = 27;

  exports['default'] = Component.extend({
    classNames: ['popover'],
    classNameBindings: ['placement'],
    attributeBindings: ['role'],

    isVisible: false,
    placement: 'bottom',
    role: 'tooltip',

    init: function init() {
      this._super.apply(this, arguments);
      this._closeOnClickOut = this._closeOnClickOut.bind(this);
      this._closeOnEsc = this._closeOnEsc.bind(this);
    },

    didRender: function didRender() {
      var _this = this;

      run.next(function () {
        var method = _this.get('isVisible') && !_this.get('isDestroyed') ? 'on' : 'off';
        var jqueryWindow = (0, _jquery['default'])(window);
        jqueryWindow[method]('click', _this._closeOnClickOut);
        jqueryWindow[method]('keyup', _this._closeOnEsc);
      });
    },

    _close: function _close() {
      if (this.get('isDestroyed')) {
        return;
      }
      this.set('isVisible', false);
      this.sendAction('close');
    },

    _closeOnClickOut: function _closeOnClickOut(e) {
      var element = this.get('element');
      if (element) {
        var clickIsInside = element === e.target || _jquery['default'].contains(element, e.target);
        var clickIsLink = (0, _jquery['default'])(e.target).prop('tagName') === 'A';

        if (!clickIsInside || clickIsLink) {
          this._close();
        }
      }
    },

    _closeOnEsc: function _closeOnEsc(e) {
      if (e.keyCode === ESC) {
        this._close();
      }
    }
  });
});