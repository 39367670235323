define('ownersup-client/components/button-add-to-calendar/component', ['exports', 'ember', 'moment', 'ownersup-client/mixins/component-name'], function (exports, _ember, _moment, _ownersupClientMixinsComponentName) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var inject = _ember['default'].inject;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend(_ownersupClientMixinsComponentName['default'], {
    metrics: inject.service(),

    name: 'button-add-to-calendar',
    classNames: ['tab-group'],

    showCalendarOptions: false,
    label: 'Add goal to calendar',
    title: '',
    description: '',
    location: document.URL,

    duration: 3,
    date: new Date(),
    startTime: computed('date', function () {
      return (0, _moment['default'])(this.get('date')).toISOString().replace(/-|:|\.\d+/g, '');
    }),
    endTime: computed('date', function () {
      return (0, _moment['default'])(this.get('date')).add(this.get('duration'), 'h').toISOString().replace(/-|:|\.\d+/g, '');
    }),

    gcalHref: computed('title', function () {
      var goalEvent = this.getProperties('title', 'description', 'location', 'startTime', 'endTime');

      return encodeURI(['https://www.google.com/calendar/render?action=TEMPLATE', '&text=' + goalEvent.title, '&dates=' + goalEvent.startTime + '/' + goalEvent.endTime, '&details=' + goalEvent.description, '&location=' + goalEvent.location, '&uid&sf=true&output=xml'].join(''));
    }),

    icsHref: computed('title', function () {
      var goalEvent = this.getProperties('title', 'description', 'location', 'startTime', 'endTime');

      return encodeURI('data:text/calendar;charset=utf8,\n          BEGIN:VCALENDAR\n          VERSION:2.0\n          BEGIN:VEVENT\n          URL:' + goalEvent.location + '\n          DTSTART:' + goalEvent.startTime + '\n          DTEND:' + goalEvent.endTime + '\n          SUMMARY:' + goalEvent.title + '\n          DESCRIPTION:' + goalEvent.description + '\n          LOCATION:' + goalEvent.location + '\n          END:VEVENT\n          END:VCALENDAR');
    }),

    actions: {
      toggleCalendarOptions: function toggleCalendarOptions() {
        this.toggleProperty('showCalendarOptions');
      },

      addToCalendar: function addToCalendar(type, href) {
        get(this, 'metrics').trackEvent({
          event: 'Schedule Goal',
          type: type
        });

        window.open(href);
      }
    }
  });
});