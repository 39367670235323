define('ownersup-client/users/invitation/accept/controller', ['exports', 'ember', 'ownersup-client/config/environment'], function (exports, _ember, _ownersupClientConfigEnvironment) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  var get = _ember['default'].get;

  // NOTE: Everything here is snake cased rather than camel cased because
  // It's not send through the normal serializer which does that conversion
  exports['default'] = Controller.extend({
    queryParams: ['invitation_token', 'email', 'stripe_access_token', 'stripe_plan'],

    stripe_plan: 'ownersup_weekly',
    password: '',
    password_confirmation: '',
    isValid: false,
    saving: false,

    invitation: computed.readOnly('model.invitation'),
    trial_end: computed.readOnly('invitation.trial_end'),
    plan: computed('invitation.stripe_plan_id', function () {
      return _ownersupClientConfigEnvironment['default'].APP.stripePlans[this.get('invitation.stripe_plan_id')];
    }),
    amountInCents: computed('plan', function () {
      return this.get('plan.amount') * 100;
    }),
    shouldCollectPaymentInfo: computed('plan', function () {
      return this.get('plan.amount') > 0;
    }),

    actions: {
      processStripeToken: function processStripeToken(token) {
        set(this, 'model.user.token', token);
      },

      setPassword: function setPassword(values) {
        get(this, 'model.user').setProperties(values);
      }
    }
  });
});