define('ownersup-client/groups/route', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    beforeModel: function beforeModel(transition) {
      var _transition$params$groups$wildcard$split = transition.params.groups.wildcard.split('/');

      var _transition$params$groups$wildcard$split2 = _slicedToArray(_transition$params$groups$wildcard$split, 3);

      var model = _transition$params$groups$wildcard$split2[1];
      var modelId = _transition$params$groups$wildcard$split2[2];

      var modelSingular = model.substring(0, model.length - 1);

      return this.transitionTo('group.' + model + '.' + modelSingular, modelId);
    }
  });
});