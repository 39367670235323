define('ownersup-client/components/report-item/component', ['exports', 'ember', 'ember-group-by'], function (exports, _ember, _emberGroupBy) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['report-item-content'],

    report: computed.alias('commentable'),
    journals: computed.filterBy('report.reportActivities', 'type', 'Journal'),
    reportActivities: computed.filter('report.reportActivities', function (activity) {
      return activity && activity.get('type') !== 'Journal';
    }),
    groupedReportActivities: (0, _emberGroupBy['default'])('reportActivities', 'type')
  });
});