define('ownersup-client/group/index/controller', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend({
    queryParams: ['page', 'page_size'],
    page: 1,
    page_size: 10,

    actions: {
      pageChanged: function pageChanged(current) {
        this.set('page', current);
      }
    }
  });
});