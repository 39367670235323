define('ownersup-client/components/calendar-week/component', ['exports', 'ember', 'ember-moment/computeds/format', 'moment'], function (exports, _ember, _emberMomentComputedsFormat, _moment) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['calendar', 'calendar-week'],

    weekStart: computed('activeDate', function () {
      var date = this.get('activeDate');
      return (0, _moment['default'])(date, 'YYYY-MM-DD').day(0).toDate();
    }),
    month: (0, _emberMomentComputedsFormat['default'])('weekStart', 'MMMM'),
    weekDays: computed('weekStart', function () {
      var start = this.get('weekStart');
      var dates = [start];
      for (var i = 1; i < 7; i++) {
        dates.push((0, _moment['default'])(start).add(i, 'days').toDate());
      }
      return dates;
    }),

    actions: {
      setActiveDate: function setActiveDate(date) {
        this.sendAction('action', date);
      }
    }
  });
});