define('ownersup-client/users/confirmation/route', ['exports', 'ember', 'ownersup-client/config/environment', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _ownersupClientConfigEnvironment, _emberSimpleAuthMixinsUnauthenticatedRouteMixin) {
  var Route = _ember['default'].Route;
  var get = _ember['default'].get;
  var run = _ember['default'].run;
  var $ = _ember['default'].$;
  var RSVP = _ember['default'].RSVP;
  var inject = _ember['default'].inject;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {
    flashMessages: inject.service(),

    beforeModel: function beforeModel(transition) {
      var _this = this;

      var flashMessages = get(this, 'flashMessages');
      var token = transition.queryParams.confirmation_token;

      if (token) {
        var url = _ownersupClientConfigEnvironment['default'].APP.API_HOST + '/v1/users/confirmation?confirmation_token=' + token;

        return $.getJSON(url).then(function () {
          run(function () {
            flashMessages.success('You\'re account has been confirmed! If you are not logged in, please do so now');
            return _this.transitionTo('/');
          });
        }, function (error) {
          run(function () {
            flashMessages.danger(error.responseText);
            return RSVP.reject(error);
          });
        });
      }
    },

    actions: {
      resendConfirmation: function resendConfirmation() {
        var _this2 = this;

        var flashMessages = get(this, 'flashMessages');

        var email = this.controller.get('email');
        var data = { api_v1_user: { email: email } };
        var url = _ownersupClientConfigEnvironment['default'].APP.API_HOST + '/v1/users/confirmation';

        return $.post(url, data).then(function () {
          run(function () {
            flashMessages.success('Please check your email for a new confirmation email');
            return _this2.transitionTo('/login');
          });
        }, function (error) {
          run(function () {
            flashMessages.danger(error.responseText);
            return RSVP.reject(error);
          });
        });
      }
    }
  });
});