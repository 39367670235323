define('ownersup-client/initializers/ember-cli-bugsnag', ['exports', 'ownersup-client/config/environment', 'bugsnag', 'ember-cli-bugsnag/utils/bugsnag-configuration'], function (exports, _ownersupClientConfigEnvironment, _bugsnag, _emberCliBugsnagUtilsBugsnagConfiguration) {
  exports['default'] = {
    name: 'ember-cli-bugsnag',

    initialize: function initialize() {
      var configVariables = _ownersupClientConfigEnvironment['default'].bugsnag || {};
      var releaseStage = _ownersupClientConfigEnvironment['default'].bugsnag.releaseStage || _ownersupClientConfigEnvironment['default'].environment;
      new _emberCliBugsnagUtilsBugsnagConfiguration['default'](configVariables, releaseStage).apply(_bugsnag['default']);
    }
  };
});