define('ownersup-client/components/tally-up/component', ['exports', 'ember', 'moment', 'ownersup-client/mixins/component-attribute-types'], function (exports, _ember, _moment, _ownersupClientMixinsComponentAttributeTypes) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = Component.extend(_ownersupClientMixinsComponentAttributeTypes['default'], {
    classNames: ['card', 'card-tally-up', 'tally-up'],
    classNameBindings: ['hasMonthlyGoal::empty-monthly-goal', 'showEditGoalForm', 'isComplete'],
    currentUser: inject.service(),
    groupMembership: computed.readOnly('currentUser.activeGroupMembership'),

    attrTypes: {
      save: true,
      create: true
    },

    date: new Date(),
    showEditGoalForm: false,
    showNewTallyUpForm: false,
    hasMonthlyGoal: computed.bool('monthlyGoalAmount'),
    notEmpty: computed.or('showEditGoalForm', 'showNewTallyUpForm', 'hasMonthlyGoal'),
    showDefault: computed('hasMonthlyGoal', 'showEditGoalForm', 'showNewTallyUpForm', function () {
      return !(this.get('showEditGoalForm') || this.get('showNewTallyUpForm')) && this.get('hasMonthlyGoal');
    }),

    monthlyGoalAmount: computed.readOnly('groupMembership.monthlyGoalAmount'),
    currentMonthTallyups: computed.filter('groupMembership.tallyups', function (tallyup) {
      return (0, _moment['default'])(tallyup.get('createdAt')).isSame(new Date(), 'month');
    }),
    amountsList: computed.mapBy('currentMonthTallyups', 'amount'),
    currentRevenue: computed.sum('amountsList'),
    currentRevenuPercentage: computed('monthlyGoalAmount', 'currentRevenue', function () {
      return Math.round(this.get('currentRevenue') / this.get('monthlyGoalAmount') * 100);
    }),
    monthlyGoalRemainder: computed('currentRevenue', 'monthlyGoalAmount', function () {
      return this.get('monthlyGoalAmount') - this.get('currentRevenue');
    }),
    isComplete: computed.gte('currentRevenuPercentage', 100),

    progressBarStyle: computed('currentRevenuPercentage', function () {
      var width = 'width: ' + this.get('currentRevenuPercentage') + '%';
      return width.htmlSafe();
    }),

    actions: {
      toggleEditGoalForm: function toggleEditGoalForm() {
        this.toggleProperty('showEditGoalForm');
      },

      saveMonthlyGoal: function saveMonthlyGoal() {
        this.sendAction('save', this.get('groupMembership'), false, 'Monthly goal updated!');
        this.toggleProperty('showEditGoalForm');
      },

      newTallyUp: function newTallyUp() {
        this.toggleProperty('showNewTallyUpForm');
      },

      saveTallyUp: function saveTallyUp() {
        var amount = parseInt(this.get('tallyupAmount'));
        if (!amount) {
          return false;
        }

        var groupMembership = this.get('groupMembership');
        var tallyup = this.attrs.create('tallyup', { groupMembership: groupMembership, amount: amount });

        this.sendAction('save', tallyup, false, 'TallyUp successfully saved');
        this.toggleProperty('showNewTallyUpForm');
        this.set('tallyupAmount', null);
      }
    }
  });
});