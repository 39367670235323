define('ownersup-client/mixins/paginated-route', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create({
    queryParams: {
      page: {
        refreshModel: true
      },
      page_size: {
        refreshModel: true
      }
    },

    afterModel: function afterModel() {
      this._super();
      window.scrollTo(0, 0);
    }
  });
});