define('ownersup-client/mixins/flash-messageable', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var inject = _ember['default'].inject;
  var Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create({
    flashMessages: inject.service(),
    _displayErrors: function _displayErrors(err) {
      var flash = get(this, 'flashMessages');
      var errors = err.errors || [{ detail: err.message }];

      errors.forEach(function (error) {
        flash.danger(error.detail);
      });
    },

    _displaySuccessMessage: function _displaySuccessMessage(message) {
      var flashMessages = get(this, 'flashMessages');
      flashMessages.clearMessages();
      flashMessages.success(message);
    }
  });
});