define('ownersup-client/group/reports/index/route', ['exports', 'ember', 'ownersup-client/mixins/paginated-route', 'ownersup-client/mixins/group-membership-content-route', 'ownersup-client/mixins/reset-scroll'], function (exports, _ember, _ownersupClientMixinsPaginatedRoute, _ownersupClientMixinsGroupMembershipContentRoute, _ownersupClientMixinsResetScroll) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend(_ownersupClientMixinsResetScroll['default'], _ownersupClientMixinsPaginatedRoute['default'], _ownersupClientMixinsGroupMembershipContentRoute['default'], {
    templateName: 'group.index',
    model: function model() {
      var params = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      params.group_membership_id = this._getRouteGroupGroupMemberhipIds();

      return this.store.query('report', params);
    },

    afterModel: function afterModel(model) {
      this._super(model);
      this.store.query('comment', { commentable_id: model.mapBy('id') });
    }
  });
});