define('ownersup-client/components/group-new/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    actions: {
      create: function create() {
        var groupAttrs = this.getProperties('name');
        this.sendAction('action', groupAttrs);
      }
    }
  });
});