define('ownersup-client/router', ['exports', 'ember', 'ownersup-client/config/environment'], function (exports, _ember, _ownersupClientConfigEnvironment) {
  var Router = _ember['default'].Router;
  var run = _ember['default'].run;
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;

  var TheRouter = Router.extend({
    location: _ownersupClientConfigEnvironment['default'].locationType,
    metrics: inject.service(),

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();
    },

    _trackPage: function _trackPage() {
      var _this = this;

      run.scheduleOnce('afterRender', this, function () {
        var page = document.location.pathname;
        var title = _this.getWithDefault('currentRouteName', 'unknown');

        get(_this, 'metrics').trackPage({ page: page, title: title });
      });
    },
    rootURL: _ownersupClientConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('login');

    this.route('users', function () {
      // this.route('new');
      this.route('confirmation');
      this.route('invitation', function () {
        this.route('accept');
      });

      this.route('password', function () {
        this.route('edit');
        this.route('new');
      });
      // this.route('user', { path: ':user_id' }, function() {
      //   // this.route('edit', { path: '/edit' });
      // });
    });

    this.route('account', function () {
      this.route('profile', function () {
        this.route('edit');
      });
      this.route('billing');
      this.route('notifications');
      this.route('security');
    });

    this.route('groups', { path: '/groups/*wildcard' });

    this.route('members', function () {});
    this.route('group', function () {
      this.route('goals', function () {
        this.route('goal', { path: '/:goal_id' });
      });

      this.route('reports', function () {
        this.route('report', { path: '/:report_id' });
      });

      this.route('messages', function () {
        this.route('message', { path: '/:message_id' });
      });
    });

    this.route('admin', function () {
      this.route('users', function () {
        this.route('new');
        this.route('user', { path: ':user_id' }, function () {
          this.route('edit', { path: '/edit' });
        });
      });

      this.route('groups', function () {
        this.route('new');
        this.route('group', { path: '/:group_id' }, function () {
          this.route('edit', { path: '/edit' });
        });
      });
      this.route('categories', {}, function () {});
    });

    this.route('goals', function () {
      this.route('new');
      this.route('goal', { path: '/:goal_id' }, function () {
        this.route('edit');
      });
    });

    this.route('reports', function () {
      this.route('new');
      this.route('report', { path: '/:report_id' }, function () {
        this.route('edit');
      });
    });

    this.route('progress', function () {
      this.route('reports', function () {
        this.route('report', { path: '/:report_id' });
      });
      this.route('goals', function () {
        this.route('goal', { path: '/:goal_id' });
      });
      this.route('messages', function () {
        this.route('message', { path: '/:message_id' });
      });
      this.route('tallyups', function () {});
    });

    this.route('messages', function () {
      this.route('message', { path: '/:message_id' }, function () {
        this.route('edit');
      });
      this.route('new');
    });

    this.route('404', { path: '*:' });
  });

  exports['default'] = TheRouter;
});