define('ownersup-client/mixins/form-component', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  var assert = _ember['default'].assert;
  exports['default'] = Mixin.create({
    didInsertElement: function didInsertElement() {
      this.$('textarea,input').eq(0).focus();
    },

    willRender: function willRender() {
      assert('You must alias your component model to `model`', this.get('model'));
      assert('You must pass a save action', this.attrs.save);
      assert('You must pass a cancel action', this.attrs.cancel);
    },

    willDestroyElement: function willDestroyElement() {
      var model = this.get('model');
      if (model && model.get('hasDirtyAttributes')) {
        this.sendAction('cancel', model, false);
      }
    },

    actions: {
      save: function save() {
        var model = this.get('model');
        model.set('isEditing', false);

        var redirect = this.getWithDefault('redirectOnSave', false);
        this.sendAction('save', model, redirect);
      },

      cancel: function cancel() {
        var model = this.get('model');
        this.sendAction('cancel', model, false);
      },

      'delete': function _delete() {
        var model = this.get('model');
        var redirect = this.getWithDefault('redirectOnSave', false);
        this.sendAction('delete', model, redirect);
      }
    }
  });
});