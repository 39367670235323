define('ownersup-client/components/message-form/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    actions: {
      submit: function submit() {
        var message = this.get('message');

        this.sendAction('save', message);
      },

      cancel: function cancel() {
        var message = this.get('message');

        this.sendAction('cancel', message);
      },

      'delete': function _delete() {
        var message = this.get('message');

        this.sendAction('delete', message);
      }
    }
  });
});