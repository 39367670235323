define('ownersup-client/mixins/rollback-record', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  var get = _ember['default'].get;
  var inject = _ember['default'].inject;
  exports['default'] = Mixin.create({
    flashMessages: inject.service(),

    _rollbackRecord: function _rollbackRecord(record, path) {
      var flashMessages = get(this, 'flashMessages');
      flashMessages.clearMessages();

      this._rollback(record);

      this._rollbackDependentRecords(record);

      if (path) {
        this.transitionTo(path);
      }
    },

    _rollbackDependentRecords: function _rollbackDependentRecords(record) {
      var _this = this;

      record.eachRelationship(function (name, descriptor) {
        if (descriptor.kind === 'hasMany') {
          record.get(name).forEach(function (item) {
            _this._rollback(item);
          });
        }
      });
    },

    _rollback: function _rollback(record) {
      if (this._isRollbackable(record)) {
        var isNew = record.get('isNew');
        if (isNew) {
          record.deleteRecord();
        } else {
          record.rollbackAttributes();
        }
      }
    },

    _isRollbackable: function _isRollbackable(record) {
      return !record.get('isSaving') && !record.get('isLoading');
    }
  });
});