define('ownersup-client/admin/groups/new/route', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    model: function model() {
      var params = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      return this.store.createRecord('group', params);
    }

    // afterModel(group) {
    //   return this.store.query('category', { ids: [1, 2, 3, 4, 5] }).then((results) => {
    //     results.forEach((category) => {
    //       return this.store.createRecord('group-category', { group, category });
    //     });
    //   });
    // }
  });
});