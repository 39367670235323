define('ownersup-client/components/modals/modal-move-group-membership/component', ['exports', 'ember', 'ownersup-client/components/modal-base/component'], function (exports, _ember, _ownersupClientComponentsModalBaseComponent) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var getProperties = _ember['default'].getProperties;
  var set = _ember['default'].set;
  var inject = _ember['default'].inject;
  exports['default'] = _ownersupClientComponentsModalBaseComponent['default'].extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.ids = [];
    },
    store: inject.service(),
    groupMembership: computed.oneWay('modalContext.groupMembership'),
    results: computed(function () {
      return this.get('store').peekAll('group');
    }),

    filteredResults: computed('results.@each.id', 'ids', function () {
      var _getProperties = getProperties(this, 'ids', 'results');

      var ids = _getProperties.ids;
      var results = _getProperties.results;

      return results.filter(function (group) {
        return ids.includes(parseInt(group.id));
      });
    }),

    actions: {
      selectResult: function selectResult(group) {
        var groupMembership = get(this, 'groupMembership');

        set(groupMembership, 'group', group);

        this._save(groupMembership, 'Success! The group membership has been moved');
      },

      handleResults: function handleResults(results) {
        var groups = results.groups;

        get(this, 'store').pushPayload({ groups: groups });
        set(this, 'ids', groups.mapBy('id'));
      }
    }
  });
});