define('ownersup-client/components/category-selection/component', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;

  // import { findFromCollectionByKey } from 'ember-macaroni';

  exports['default'] = Component.extend({
    classNames: ['category-selection-component'],
    selection: false,

    selectionId: computed('selection', {
      get: function get() {
        return this.get('selection.id');
      },
      set: function set(key, id) {
        var _get$filter = this.get('categories').filter(function (c) {
          return c.get('id') === id;
        });

        var _get$filter2 = _slicedToArray(_get$filter, 1);

        var category = _get$filter2[0];

        this.set('selection', category);

        return id;
      }
    })
  });
});