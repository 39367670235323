define('ownersup-client/components/input-search/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var getProperties = _ember['default'].getProperties;
  var set = _ember['default'].set;
  var Component = _ember['default'].Component;
  var inject = _ember['default'].inject;
  exports['default'] = Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.ids = [];
      this.query = null;
      this.hasSearched = false;
    },

    stripePlanId: 'ownersup_297',
    stripeCouponId: null,
    store: inject.service(),

    actions: {
      handleResults: function handleResults(results) {
        var users = results.users;

        get(this, 'store').pushPayload({ users: users });
        set(this, 'ids', users.mapBy('id'));
        set(this, 'hasSearched', true);
      },

      createGroupMembership: function createGroupMembership(user, role) {
        this.sendAction('createGroupMembership', user, role);
        this._clearForm();
      },

      inviteUser: function inviteUser() {
        var options = this.getProperties('email', 'stripePlanId', 'stripeCouponId', 'stripeTrialEnd');
        this.sendAction('inviteUser', options);
        this._clearForm();
      }
    },

    results: computed(function () {
      return this.get('store').peekAll('user');
    }),

    filteredResults: computed('results.@each.id', 'ids', function () {
      var _getProperties = getProperties(this, 'ids', 'results');

      var ids = _getProperties.ids;
      var results = _getProperties.results;

      return results.filter(function (group) {
        return ids.includes(parseInt(group.id));
      });
    }),

    _clearForm: function _clearForm() {
      this.setProperties({
        query: '',
        ids: [],
        hasSearched: false
      });
    }
  });
});