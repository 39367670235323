define('ownersup-client/members/route', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  var get = _ember['default'].get;
  var inject = _ember['default'].inject;
  exports['default'] = Route.extend({
    currentUser: inject.service(),
    model: function model() {
      return get(this, 'currentUser.activeGroup');
    }

    // setupController(controller, model) {
    //   this._super(controller, model);
    //
    //   let groupMembershipIds = model.group.get('groupMemberships').getEach('id');
    //
    //   controller.set('model.goals',
    //     this.store.query('goal', {
    //       groupMembership: groupMembershipIds
    //     })
    //   );
    // }
  });
});