define('ownersup-client/components/ownersup-search/component', ['exports', 'ember', 'ember-hypersearch'], function (exports, _ember, _emberHypersearch) {
  var service = _ember['default'].inject.service;
  var get = _ember['default'].get;
  exports['default'] = _emberHypersearch['default'].extend({
    store: service(),
    request: function request(query) {
      var model = get(this, 'model');
      var adapter = get(this, 'store').adapterFor(get(this, 'model'));
      var url = adapter.buildURL.apply(adapter, [model]);
      var data = { q: query };

      return adapter.ajax.apply(adapter, [url, 'GET', { data: data }]);
    }
  });
});