define('ownersup-client/index/controller', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend({
    actions: {
      createRecord: function createRecord(type) {
        var attributes = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

        return this.store.createRecord(type, attributes);
      }
    },
    queryParams: ['activeDate'],
    activeDate: (0, _moment['default'])().format('YYYY-MM-DD')
  });
});