define('ownersup-client/report/model', ['exports', 'ember', 'ember-data', 'ownersup-client/commentable/model', 'ownersup-client/mixins/group-membershipable'], function (exports, _ember, _emberData, _ownersupClientCommentableModel, _ownersupClientMixinsGroupMembershipable) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = _ownersupClientCommentableModel['default'].extend(_ownersupClientMixinsGroupMembershipable['default'], {
    reportActivitiesCount: attr('number'),
    reportActivities: hasMany('report-activity', { async: true }),

    status: attr('string', { defaultValue: 'draft' }),
    isDraft: computed.equal('status', 'draft'),
    isPublished: computed.equal('status', 'published'),

    activitySort: ['createdAt:asc', 'updatedAt:asc'],
    accomplishments: computed.filterBy('reportActivities', 'type', 'Accomplishment'),
    lessons: computed.filterBy('reportActivities', 'type', 'Lesson'),
    journals: computed.filterBy('reportActivities', 'type', 'Journal'),

    reportActivitiesSorted: computed.sort('reportActivities', 'activitySort'),
    accomplishmentsSorted: computed.sort('accomplishments', 'activitySort'),
    lessonsSorted: computed.sort('lessons', 'activitySort'),

    isEmpty: computed('reportActivities.@each.notEmpty', function () {
      return !this.get('reportActivities').isAny('notEmpty');
    }),
    isNewAndEmpty: computed.and('isNew', 'isEmpty'),

    ready: function ready() {
      this._addDefaultActivity();
    },

    _addDefaultActivity: function _addDefaultActivity() {
      var _this = this;

      var createdAt = get(this, 'createdAt');

      get(this, 'reportActivities').then(function (reportActivities) {
        if (get(_this, 'journals.length') === 0) {
          reportActivities.createRecord({ type: 'Journal', createdAt: createdAt });
        }
        if (get(_this, 'accomplishments.length') === 0) {
          reportActivities.createRecord({ type: 'Accomplishment', createdAt: createdAt });
        }
        if (get(_this, 'lessons.length') === 0) {
          reportActivities.createRecord({ type: 'Lesson', createdAt: createdAt });
        }
      });
    }

  });
});