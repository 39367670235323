define('ownersup-client/components/chart-tallyup/component', ['exports', 'ember', 'moment', 'ownersup-client/helpers/number-to-k', 'ownersup-client/components/chart-base/component'], function (exports, _ember, _moment, _ownersupClientHelpersNumberToK, _ownersupClientComponentsChartBaseComponent) {
  var computed = _ember['default'].computed;

  function groupTallyupDataByMonth(tallyups) {
    var raw = {};

    tallyups.forEach(function (tallyup) {
      var data = tallyup.getProperties('amount', 'monthlyGoalAmount', 'createdAt');
      var createdAt = (0, _moment['default'])(data.createdAt).format('YYYY-MM-01');

      if (!raw[createdAt]) {
        raw[createdAt] = { tallyups: [], goal: 0 };
      }

      raw[createdAt].tallyups.push(data.amount);
      raw[createdAt].goal = data.monthlyGoalAmount;
    });

    return raw;
  }

  function processEachMonth(grouped) {
    for (var month in grouped) {
      grouped[month].tallyups = grouped[month].tallyups.reduce(function (previousValue, tallyup) {
        return previousValue + tallyup;
      }, 0);
    }
    return grouped;
  }

  function flattenToColumns(processed) {
    var months = Object.keys(processed);
    months.unshift('x');
    var tallyups = ['tallyup'];
    var goals = ['goal'];

    for (var month in processed) {
      tallyups.push(processed[month].tallyups);
      goals.push(processed[month].goal);
    }

    return [months, tallyups, goals];
  }

  exports['default'] = _ownersupClientComponentsChartBaseComponent['default'].extend({
    classNames: ['chart-tallyup'],

    tallyupColumns: computed('model', function () {
      var grouped = groupTallyupDataByMonth(this.get('model'));
      var processed = processEachMonth(grouped);
      var flattened = flattenToColumns(processed);

      return flattened;
    }),

    data: computed('tallyupColumns', function () {
      return {
        x: 'x',
        columns: this.get('tallyupColumns'),
        types: {
          tallyup: 'bar',
          goal: 'line'
        },
        colors: {
          tallyup: 'rgba(255,255,255,.8)',
          goal: 'rgba(100,157,61,.8)'
        },
        names: {
          tallyup: 'TallyUp Total',
          goal: 'Monthly Revenue Goal'
        },
        labels: {
          format: function format(value) {
            return (0, _ownersupClientHelpersNumberToK.numberToK)(value, { html: false });
          }
        }
      };
    }),

    axis: {
      x: {
        type: 'timeseries',
        tick: { format: function format(d) {
            return (0, _moment['default'])(d).format('MMMM');
          } }
      },
      y: {
        tick: { format: function format(value) {
            return (0, _ownersupClientHelpersNumberToK.numberToK)(value, { html: false });
          } }
      }
    },

    grid: {
      x: { show: true },
      y: { show: true }
    }
  });
});