define('ownersup-client/admin/users/user/edit/route', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  var get = _ember['default'].get;
  var inject = _ember['default'].inject;
  exports['default'] = Route.extend({
    flashMessages: inject.service(),

    actions: {
      willTransition: function willTransition() /*transition*/{},

      submit: function submit() {
        var _this = this;

        var flashMessages = get(this, 'flashMessages');

        var user = this.controller.get('model');

        user.save().then(function () {
          _this.transitionTo('admin.users.user', user.id);
          flashMessages.success('Successfully saved!');
        })['catch'](function (err) {
          flashMessages.danger(err.message);
        });
      },

      'delete': function _delete(user) {
        var _this2 = this;

        var flashMessages = get(this, 'flashMessages');

        user.destroyRecord().then(function () {
          flashMessages.success('Successfully deleted!');
          _this2.transitionTo('admin.users');
        })['catch'](function (err) {
          flashMessages.danger(err.message);
        });
      }
    }
  });
});