define('ownersup-client/progress/goals/index/route', ['exports', 'ember', 'ember-infinity/mixins/route'], function (exports, _ember, _emberInfinityMixinsRoute) {
  var Route = _ember['default'].Route;
  var inject = _ember['default'].inject;
  exports['default'] = Route.extend(_emberInfinityMixinsRoute['default'], {
    currentUser: inject.service(),
    perPageParam: 'page_size',
    totalPagesParam: 'meta.pagination.total_pages',

    model: function model() {
      var params = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      params.group_membership_id = [this.get('currentUser.activeGroupMembershipId')];
      params.page_size = 10;
      return this.infinityModel('goal', params);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('listSort', ['status:asc', 'dueDate:asc', 'createdAt:desc']);
    }

  });
});