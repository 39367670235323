define('ownersup-client/mixins/trackable-record', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  var get = _ember['default'].get;
  var inject = _ember['default'].inject;
  exports['default'] = Mixin.create({
    metrics: inject.service(),

    trackModelEvent: function trackModelEvent(model) {
      var customAction = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

      var action = customAction || (model.get('isNew') ? 'Created' : 'Updated');
      var name = model.constructor.modelName;

      // One off changes
      if (name === 'group-membership') {
        if (Object.keys(model.changedAttributes()).includes('monthlyGoalAmount')) {
          name = 'monthly goal';
        }
      }

      get(this, 'metrics').trackEvent({
        event: action + ' ' + name,
        id: model.get('id')
      });
    }
  });
});