define('ownersup-client/helpers/time-by-tz', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.timeByTz = timeByTz;
  var Helper = _ember['default'].Helper;

  function timeByTz(_ref /*, hash*/) {
    var _ref2 = _slicedToArray(_ref, 2);

    var date = _ref2[0];
    var timeZone = _ref2[1];

    return (0, _moment['default'])(date).tz(timeZone).format('h:mm A z');
  }

  exports['default'] = Helper.helper(timeByTz);
});