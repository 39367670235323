define('ownersup-client/reports/report/edit/route', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    actions: {
      willTransition: function willTransition(transition) {
        transition.data.model = this.controller.get('model');
        return true;
      }
    }
  });
});