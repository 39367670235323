define('ownersup-client/components/user-avatar/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['user-avatar'],
    classNameBindings: ['avatarOnly', 'withoutCompany'],

    avatarOnly: false,
    withoutCompany: false,

    linkToUser: false,
    adminLink: false,
    userLink: computed('adminLink', function () {
      return this.get('adminlink') ? 'admin.users.user' : 'user';
    })
  });
});