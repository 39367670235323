define('ownersup-client/components/chart-base/component', ['exports', 'ember', 'ownersup-client/mixins/spinnerable', 'ownersup-client/mixins/component-name'], function (exports, _ember, _ownersupClientMixinsSpinnerable, _ownersupClientMixinsComponentName) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_ownersupClientMixinsSpinnerable['default'], _ownersupClientMixinsComponentName['default'], {
    name: 'progress-chart',

    isEmpty: computed('model', function () {
      return this.get('model.length') < 1;
    }),

    data: false,
    axis: false,
    grid: false,
    color: {
      pattern: ['#F06352', // Brand primary
      '#98CA76', // Green
      '#4ABFB6', // Turquoise
      '#759D59', // Dark Green
      '#F88F48', // Orange
      '#458ECC' // Blue
      ]
    }
  });
});