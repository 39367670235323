define('ownersup-client/category/model', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = Model.extend({
    name: attr('string'),
    slug: attr('string'),
    description: attr('string'),
    groupCategories: hasMany('group-categories', { async: false }),

    isEditing: attr('boolean', { defaultValue: false })
  });
});