define('ownersup-client/helpers/time', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.time = time;
  var Helper = _ember['default'].Helper;
  var EmberS = _ember['default'].String;

  function time(_ref) {
    var _ref2 = _slicedToArray(_ref, 1);

    var date = _ref2[0];

    var _ref3 = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

    var relative = _ref3.relative;
    var showLong = _ref3.showLong;

    date = (0, _moment['default'])(date);
    var format = showLong ? 'dddd, MMMM Do YYYY' : 'M/D/YY';
    var formattedDate = date.format(format);

    var dateString = undefined;

    if (relative) {
      var ago = date.fromNow();
      dateString = '<span class="relative">' + ago + '</span><span class="full">' + formattedDate + '</span>';
    } else {
      dateString = formattedDate;
    }

    var html = '<time datetime="' + date.toISOString() + '">' + dateString + '</time>';

    return EmberS.htmlSafe(html);
  }

  exports['default'] = Helper.helper(time);
});