define('ownersup-client/progress/messages/index/route', ['exports', 'ember', 'ember-infinity/mixins/route'], function (exports, _ember, _emberInfinityMixinsRoute) {
  var Route = _ember['default'].Route;
  var inject = _ember['default'].inject;

  // import PaginatedRouteMixin from 'ownersup-client/mixins/paginated-route';

  exports['default'] = Route.extend(_emberInfinityMixinsRoute['default'], {
    currentUser: inject.service(),
    perPageParam: 'page_size',
    totalPagesParam: 'meta.pagination.total_pages',

    model: function model() {
      var params = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      params.group_membership_id = [this.get('currentUser.activeGroupMembershipId')];
      params.page_size = 10;

      return this.infinityModel('message', params);
    }
  });
});