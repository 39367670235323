define('ownersup-client/tallyup/model', ['exports', 'ember-data', 'ownersup-client/mixins/timestampable', 'ownersup-client/mixins/authorizable', 'ownersup-client/mixins/group-membershipable'], function (exports, _emberData, _ownersupClientMixinsTimestampable, _ownersupClientMixinsAuthorizable, _ownersupClientMixinsGroupMembershipable) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend(_ownersupClientMixinsGroupMembershipable['default'], _ownersupClientMixinsAuthorizable['default'], _ownersupClientMixinsTimestampable['default'], {
    amount: attr('number'),
    groupMembership: belongsTo('group-membership', { inverse: 'tallyups' }),
    monthlyGoalAmount: attr('number'),

    isEditing: attr('boolean', { defaultValue: false }),

    didUpdate: function didUpdate() {
      this.set('isEditing', false);
    }
  });
});