define('ownersup-client/mixins/confirmable', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create({
    isConfirming: false,
    confirmationMessage: 'Are you sure?',

    actions: {
      confirm: function confirm() {
        this._confirm();
      },
      denied: function denied() {
        this._deny();
      }
    },

    _confirm: function _confirm() {
      this.set('isConfirming', true);
    },

    _deny: function _deny() {
      this.set('isConfirming', false);
    }
  });
});