define('ownersup-client/components/goal-item/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var isBlank = _ember['default'].isBlank;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var inject = _ember['default'].inject;
  exports['default'] = Component.extend({
    flashMessages: inject.service(),
    goal: computed.alias('commentable'),
    shouldReflect: false,
    noReflection: computed.lt('goal.reflection.length', 5),

    actions: {
      complete: function complete(result) {
        var goal = get(this, 'goal');

        goal.setProperties({
          result: result,
          'status': 'completed',
          'completedAt': Date.now()
        });

        this.toggleProperty('shouldReflect');
      },

      submit: function submit() {
        var goal = get(this, 'goal');
        var flashMessages = get(this, 'flashMessages');

        if (isBlank(get(goal, 'reflection'))) {
          flashMessages.danger('Take some time to reflect on your goal before you submit it!');
          return false;
        }

        this.toggleProperty('shouldReflect');

        this.sendAction('submit', goal, 'progress.goals');
      },

      cancel: function cancel() {
        this.toggleProperty('shouldReflect');

        var goal = get(this, 'goal');
        this.sendAction('cancel', goal, false);
      }
    }
  });
});