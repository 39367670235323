define('ownersup-client/goal/model', ['exports', 'ember', 'ember-data', 'ownersup-client/commentable/model', 'ownersup-client/mixins/group-membershipable', 'ownersup-client/mixins/categorizable', 'moment'], function (exports, _ember, _emberData, _ownersupClientCommentableModel, _ownersupClientMixinsGroupMembershipable, _ownersupClientMixinsCategorizable, _moment) {
  var computed = _ember['default'].computed;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;

  var results = ['hit', 'missed'];
  var statuses = ['active', 'completed'];

  exports['default'] = _ownersupClientCommentableModel['default'].extend(_ownersupClientMixinsGroupMembershipable['default'], _ownersupClientMixinsCategorizable['default'], {
    statuses: statuses,
    results: results,
    title: attr('string'),
    description: attr('string'),
    steps: attr('string'),
    status: attr('string', { defaultValue: statuses[0] }),
    result: attr('string'),
    reflection: attr('string'),
    completedAt: attr('date'),
    dueDate: attr('date'),
    isDueToday: computed('dueDate', function () {
      return (0, _moment['default'])(this.get('dueDate')).isSame((0, _moment['default'])(), 'day');
    }),
    isOverdue: computed('dueDate', function () {
      return (0, _moment['default'])(this.get('dueDate')).isBefore((0, _moment['default'])(), 'day');
    }),
    displayState: computed('status', 'idDueToday', 'isOverdue', function () {
      var state = ['goal-' + this.get('status')];

      if (this.get('status') === 'active') {
        if (this.get('isDueToday')) {
          state.push('goal-due-today');
        }
        if (this.get('isOverdue')) {
          state.push('goal-overdue');
        }
      }

      return state.join(' ');
    }),
    category: belongsTo('category', { async: true }),

    isCompleted: computed.equal('status', 'completed')
  });
});