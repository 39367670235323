define('ownersup-client/login/route', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'ownersup-client/mixins/trackable-record'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin, _ownersupClientMixinsTrackableRecord) {
  var Route = _ember['default'].Route;
  var Logger = _ember['default'].Logger;
  var inject = _ember['default'].inject;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], _ownersupClientMixinsTrackableRecord['default'], {
    flashMessages: inject.service(),
    session: inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
    },

    _authenticationFailed: function _authenticationFailed(error) {
      Logger.debug('ApplicationRoute#actions#sessionAuthenticationFailed');

      this.get('flashMessages').danger(error.error);
      // Lord forgive me
      if (/confirm your email/.test(error.error)) {
        this.transitionTo('users.confirmation');
      }
    },

    actions: {
      login: function login() {
        var _this = this;

        this.controller.set('isSaving', true);

        var _controller$getProperties = this.controller.getProperties('identification', 'password');

        var identification = _controller$getProperties.identification;
        var password = _controller$getProperties.password;

        this.get('session').authenticate('authenticator:devise', identification, password).then(function () {
          _this.get('metrics').trackEvent({
            event: 'Logged In',
            email: identification
          });
        })['catch'](function (error) {
          _this._authenticationFailed(error);
        }) // Tests fail unless I catch the failed promise here
        ['finally'](function () {
          _this.controller.set('isSaving', true);
        });
      }
    }
  });
});