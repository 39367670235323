define('ownersup-client/components/chart-reports/component', ['exports', 'ember', 'ownersup-client/components/chart-base/component', 'ownersup-client/config/environment'], function (exports, _ember, _ownersupClientComponentsChartBaseComponent, _ownersupClientConfigEnvironment) {
  var computed = _ember['default'].computed;
  exports['default'] = _ownersupClientComponentsChartBaseComponent['default'].extend({
    classNames: ['chart-reports'],

    init: function init() {
      this._super.apply(this, arguments);

      this.json = this.json || [];
    },

    isEmpty: computed.lt('json.length', 1),

    groupByFormat: computed('groupBy', function () {
      var groupBy = this.get('groupBy');
      if (groupBy === 'month') {
        return 'MMMM';
      } else if (groupBy === 'week') {
        return 'YYYY-WW';
      } else {
        return 'YYYY-MM-DD';
      }
    }),

    data: computed('json', function () {
      return {
        json: this.get('json'),
        keys: {
          x: 'date', // it's possible to specify 'x' when category axis
          value: this.get('categoryNames')
        },
        type: 'bar',
        order: 'asc',
        colors: _ownersupClientConfigEnvironment['default'].APP.categoryColors,
        groups: [this.get('categoryNames')]
      };
    }),

    grid: {
      y: {
        show: true
      }
    },

    axis: computed('groupBy', function () {
      return {
        x: {
          type: 'timeseries',
          tick: {
            fit: true
          }
        }
      };
    }),

    tooltip: computed('groupBy', function () {
      return {
        contents: function contents(d, defaultTitleFormat, defaultValueFormat, color) {
          var total = d.reduce(function (previous, current) {
            return previous + current.value;
          }, 0);
          var html = ['<table class="c3-tooltip"><tbody>\n          <tr><th colspan="2">' + defaultTitleFormat(d[0].x) + '</th></tr>'];

          d.forEach(function (row) {
            html.push('<tr class="c3-tooltip-name-' + row.name + '">\n            <td class="name"><span style="background-color:' + color(row.id) + '"></span>' + row.name + '</td>\n            <td class="value">' + Math.floor(row.value / total * 100) + '%</td>\n          </tr>');
          });
          html.push('</tbody></table>');

          return html.join('');
        }
      };
    }),

    categoryNames: computed.mapBy('categories', 'name'),

    defaultGroupValues: computed('categoryNames', function () {
      var types = {};

      this.get('categoryNames').forEach(function (category) {
        types[category] = 0;
      });

      return types;
    })
  });
});
/*jshint multistr: true */