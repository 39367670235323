define('ownersup-client/components/tender-invoice-history/component', ['exports', 'ember', 'ember-in-viewport'], function (exports, _ember, _emberInViewport) {
  var get = _ember['default'].get;
  var Component = _ember['default'].Component;
  var inject = _ember['default'].inject;
  exports['default'] = Component.extend(_emberInViewport['default'], {
    tender: inject.service(),
    classNames: ['invoice-history'],
    attributeBindings: ['data-ih-history'],

    'data-ih-history': true,
    user: null,
    authToken: null,
    date: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      get(this, 'user').customAction('billing').then(function (response) {
        get(_this, 'tender').setup({
          date: response.date,
          auth_token: response.signature,
          email: get(_this, 'user.email')
        });
      });
    }
  });
});