define('ownersup-client/admin/groups/index/route', ['exports', 'ember', 'ember-infinity/mixins/route'], function (exports, _ember, _emberInfinityMixinsRoute) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend(_emberInfinityMixinsRoute['default'], {
    perPageParam: 'page_size',
    totalPagesParam: 'meta.pagination.total_pages',
    model: function model() {
      var params = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      params.page_size = 10;
      return this.infinityModel('group', params);
    }
  });
});