define('ownersup-client/reports/new/route', ['exports', 'ember', 'ownersup-client/mixins/group-membership-content-route', 'ownersup-client/mixins/reset-scroll', 'moment'], function (exports, _ember, _ownersupClientMixinsGroupMembershipContentRoute, _ownersupClientMixinsResetScroll, _moment) {
  var Route = _ember['default'].Route;
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;
  exports['default'] = Route.extend(_ownersupClientMixinsGroupMembershipContentRoute['default'], _ownersupClientMixinsResetScroll['default'], {
    session: inject.service('session'),
    currentUser: inject.service('current-user'),

    model: function model(params) {
      var _this = this;

      return this.store.query('report', {
        status: 'draft',
        group_membership_id: [this.get('currentUser.activeGroupMembershipId')]
      }).then(function (drafts) {
        if (get(drafts, 'length') > 0) {
          return get(drafts, 'firstObject');
        } else {
          var createdAt = params.createdAt ? (0, _moment['default'])(params.createdAt + ' 17').toDate() : (0, _moment['default'])().toDate();
          var groupMembership = _this.get('currentUser.activeGroupMembership');

          return _this.store.createRecord('report', { createdAt: createdAt, groupMembership: groupMembership });
        }
      });
    },

    actions: {
      willTransition: function willTransition(transition) {
        transition.data.model = this.controller.get('model');
        return true;
      }
    }
  });
});