define('ownersup-client/components/comment-form/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var inject = _ember['default'].inject;
  exports['default'] = Component.extend({
    currentUser: inject.service('current-user'),
    classNames: ['comment-form'],

    didInsertElement: function didInsertElement() {
      this.$('textarea').focus();
    },

    willDestroyElement: function willDestroyElement() {
      var comment = this.get('comment');
      if (comment && comment.get('hasDirtyAttributes')) {
        this.sendAction('cancel', comment, false);
      }
    },

    actions: {
      saveComment: function saveComment() {
        var comment = this.get('comment');
        comment.set('isEditing', false);
        this.sendAction('saveComment', comment);
      },

      cancel: function cancel() {
        var comment = this.get('comment');
        this.sendAction('cancel', comment, false);
      }
    }
  });
});