define('ownersup-client/instance-initializers/browser/ember-href-to', ['exports', 'ember', 'ember-href-to/href-to'], function (exports, _ember, _emberHrefToHrefTo) {
  exports['default'] = {
    name: 'ember-href-to',
    initialize: function initialize(applicationInstance) {
      var $body = _ember['default'].$(document.body);
      $body.off('click.href-to', 'a');

      $body.on('click.href-to', 'a', function (e) {
        var hrefTo = new _emberHrefToHrefTo['default'](applicationInstance, e);
        hrefTo.maybeHandle();

        return true;
      });
    }
  };
});