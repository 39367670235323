define('ownersup-client/components/button-confirmable/component', ['exports', 'ember', 'ownersup-client/mixins/component-name', 'ownersup-client/mixins/confirmable'], function (exports, _ember, _ownersupClientMixinsComponentName, _ownersupClientMixinsConfirmable) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var typeOf = _ember['default'].typeOf;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = Component.extend(_ownersupClientMixinsComponentName['default'], _ownersupClientMixinsConfirmable['default'], {
    name: 'button-confirmable',
    classNameBindings: ['isConfirming'],
    shouldConfirm: true,
    verb: 'delete',
    label: computed('verb', function () {
      return this.get('verb').capitalize();
    }),

    actions: {
      confirm: function confirm() {
        if (get(this, 'shouldConfirm')) {
          set(this, 'isConfirming', true);
        } else {
          this.send('confirmed');
        }
      },
      confirmed: function confirmed() {
        this._handleAction('confirmed', this.get('model'), false);
      }
    },

    _handleAction: function _handleAction(actionName) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      if (this.attrs && typeOf(this.attrs[actionName]) === 'function') {
        var _attrs;

        (_attrs = this.attrs)[actionName].apply(_attrs, args);
      } else {
        this.sendAction.apply(this, [actionName].concat(args));
      }
    }
  });
});