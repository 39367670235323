define('ownersup-client/progress/messages/message/route', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    model: function model() {
      var params = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      return this.store.findRecord('message', params.message_id);
    }

  });
});