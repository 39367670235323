define('ownersup-client/application/adapter', ['exports', 'ownersup-client/config/environment', 'active-model-adapter', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _ownersupClientConfigEnvironment, _activeModelAdapter, _emberSimpleAuthMixinsDataAdapterMixin) {
  exports['default'] = _activeModelAdapter['default'].extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], {
    authorizer: 'authorizer:application',
    coalesceFindRequests: true,
    namespace: 'v1',
    host: _ownersupClientConfigEnvironment['default'].APP.API_HOST,
    headers: {
      'Accept': 'application/json'
    },

    shouldReloadAll: function shouldReloadAll() /*store, snapshotRecordArray*/{
      return true;
    },

    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() /*store, snapshotRecordArray*/{
      return true;
    },

    shouldReloadRecord: function shouldReloadRecord() /*store, snapshot*/{
      return false;
    },

    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() /*store, snapshot*/{
      return false;
    }
  });
});