define('ownersup-client/components/category-help/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['category-help-component'],
    showHelp: false,

    actions: {
      toggleHelp: function toggleHelp() {
        this.toggleProperty('showHelp');
      }
    }
  });
});