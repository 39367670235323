define('ownersup-client/users/password/new/route', ['exports', 'ember', 'ownersup-client/config/environment', 'ownersup-client/utils/format-error-json'], function (exports, _ember, _ownersupClientConfigEnvironment, _ownersupClientUtilsFormatErrorJson) {
  var Route = _ember['default'].Route;
  var get = _ember['default'].get;
  var $ = _ember['default'].$;
  var run = _ember['default'].run;
  var inject = _ember['default'].inject;

  var url = _ownersupClientConfigEnvironment['default'].APP.API_HOST + '/v1/users/password';

  exports['default'] = Route.extend({
    flashMessages: inject.service(),

    actions: {
      requestReset: function requestReset() {
        var flashMessages = get(this, 'flashMessages');
        var properties = this.controller.getProperties('email');
        var data = { api_v1_user: properties };

        $.post(url, data).then(function () {
          run(function () {
            flashMessages.success('An email has been sent with instructions to reset your password');
          });
        }, function (error) {
          run(function () {
            flashMessages.danger((0, _ownersupClientUtilsFormatErrorJson['default'])(error));
          });
        });
      }
    }
  });
});