define('ownersup-client/reports/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ownersup-client/mixins/group-membership-content-route', 'ownersup-client/mixins/flash-messageable', 'ownersup-client/mixins/trackable-record'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ownersupClientMixinsGroupMembershipContentRoute, _ownersupClientMixinsFlashMessageable, _ownersupClientMixinsTrackableRecord) {
  var get = _ember['default'].get;
  var RSVP = _ember['default'].RSVP;
  var Route = _ember['default'].Route;
  var inject = _ember['default'].inject;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _ownersupClientMixinsGroupMembershipContentRoute['default'], _ownersupClientMixinsFlashMessageable['default'], _ownersupClientMixinsTrackableRecord['default'], {
    flashMessages: inject.service(),

    actions: {
      saveRecord: function saveRecord(report) {
        var _this = this;

        var flashMessages = get(this, 'flashMessages');

        this.trackModelEvent(report);

        report.save().then(function (report) {
          return _this._saveReportActivities(report);
        }).then(function () {
          flashMessages.success('Successfully saved!');
          if (get(report, 'isPublished')) {
            _this.transitionTo('index');
          }
        })['catch'](function (err) {
          _this._displayErrors(err);
        });
      }
    },

    _saveReportActivities: function _saveReportActivities(report) {
      var promises = [];

      report.get('reportActivitiesSorted').forEach(function (item) {
        if (item.get('hasDirtyAttributes') && item.get('notEmpty')) {
          promises.push(item.save());
        }
      });

      return RSVP.all(promises);
    },

    _isNewButUnmodified: function _isNewButUnmodified(model) {
      return model.get('isNewAndEmpty');
    }
  });
});