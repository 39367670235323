define('ownersup-client/admin/categories/index/route', ['exports', 'ember', 'ownersup-client/mixins/paginated-route'], function (exports, _ember, _ownersupClientMixinsPaginatedRoute) {
  var Route = _ember['default'].Route;
  var inject = _ember['default'].inject;
  exports['default'] = Route.extend(_ownersupClientMixinsPaginatedRoute['default'], {
    flashMessages: inject.service(),
    model: function model(params) {
      return this.store.query('category', params);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('showNewCategory', false);
    },

    actions: {
      showNewCategoryForm: function showNewCategoryForm() {
        var newCategory = this.store.createRecord('category');

        this.controller.setProperties({
          showNewCategory: true,
          newCategory: newCategory
        });
      },

      editCategory: function editCategory(category) {
        category.toggleProperty('isEditing');
      },

      saveCategory: function saveCategory(category) {
        var _this = this;

        var flashMessages = this.get('flashMessages');
        category.save().then(function () {
          flashMessages.success('Category created!');
          _this.controller.set('showNewCategory', false);
          _this.refresh();
        });
      }
    }
  });
});