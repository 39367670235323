define('ownersup-client/utils/format-error-json', ['exports'], function (exports) {
  exports['default'] = formanErrorJson;

  function formanErrorJson(errorObject) {
    var errors = errorObject.responseJSON;
    var messages = [];

    for (var error in errors) {
      messages.push(error + ' ' + errors[error].join(', '));
    }

    if (messages.length > 0) {
      return messages.join('<br />');
    }

    return 'Uh Oh! Something went wrong';
  }
});