define('ownersup-client/account/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var Route = _ember['default'].Route;
  var inject = _ember['default'].inject;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    currentUser: inject.service('current-user'),

    model: function model() {
      return this.get('currentUser.user');
    }
  });
});