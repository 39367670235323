define('ownersup-client/current-user/service', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;
  var isEmpty = _ember['default'].isEmpty;
  var inject = _ember['default'].inject;
  var _get = _ember['default'].get;
  var set = _ember['default'].set;
  var Service = _ember['default'].Service;
  exports['default'] = Service.extend({
    session: inject.service('session'),
    store: inject.service('store'),

    role: computed.reads('session.data.authenticated.role'),
    isAdmin: computed.equal('role', 'admin'),
    isUser: computed.equal('role', 'user'),

    id: computed.reads('session.data.authenticated.id'),
    groupMembershipIds: computed.reads('user.groupMembershipIds'),
    hasGroupMemberships: computed.bool('groupMembershipIds'),

    user: computed('id', function () {
      var id = _get(this, 'id');

      if (isPresent(id)) {
        return _get(this, 'store').findRecord('user', id);
      }

      return null;
    }),

    activeGroupMembershipId: computed('user', 'session.data.activeGroupMembershipId', {
      get: function get() {
        var key = 'session.data.activeGroupMembershipId';
        var activeGroupMembershipId = _get(this, key);
        var groupMembershipIds = _get(this, 'groupMembershipIds');
        var hasGroupMemberships = _get(this, 'hasGroupMemberships');

        if (!_get(this, 'session.isAuthenticated') || !hasGroupMemberships) {
          return null;
        }

        if (hasGroupMemberships && (isEmpty(activeGroupMembershipId) || this._isActiveMemberhipStale(groupMembershipIds, activeGroupMembershipId))) {
          activeGroupMembershipId = _get(this, 'groupMembershipIds.firstObject');

          set(this, key, activeGroupMembershipId);
        }

        return activeGroupMembershipId;
      },
      set: function set(key, id) {
        _get(this, 'session').set('data.activeGroupMembershipId', id);

        return id;
      }
    }),

    activeGroupMembership: computed('activeGroupMembershipId', function () {
      var groupMembershipId = _get(this, 'activeGroupMembershipId');
      var store = _get(this, 'store');

      if (!groupMembershipId) {
        return null;
      }

      if (store.hasRecordForId('group-membership', groupMembershipId)) {
        return store.peekRecord('group-membership', groupMembershipId);
      } else {
        return store.findRecord('group-membership', groupMembershipId);
      }
    }),

    activeGroup: computed.reads('activeGroupMembership.group'),

    _isActiveMemberhipStale: function _isActiveMemberhipStale(groupMembershipIds, activeGroupMembershipId) {
      return !groupMembershipIds.includes(activeGroupMembershipId);
    }
  });
});