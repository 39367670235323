define('ownersup-client/helpers/href-to', ['exports', 'ember-href-to/helpers/href-to'], function (exports, _emberHrefToHelpersHrefTo) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberHrefToHelpersHrefTo['default'];
    }
  });
  Object.defineProperty(exports, 'hrefTo', {
    enumerable: true,
    get: function get() {
      return _emberHrefToHelpersHrefTo.hrefTo;
    }
  });
});