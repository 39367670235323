define('ownersup-client/group/goals/goal/route', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = Route.extend({
    model: function model(params) {
      return RSVP.hash({
        group: this.modelFor('group').group,
        goal: this.store.findRecord('goal', params.goal_id)
      });
    }
  });
});