define('ownersup-client/mixins/group-membershipable', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var Mixin = _ember['default'].Mixin;
  var computed = _ember['default'].computed;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Mixin.create({
    groupMembership: belongsTo('groupMembership', { async: true }),
    user: computed.reads('groupMembership.user'),
    group: computed.reads('groupMembership.group'),
    isOwner: attr('boolean', { defaultValue: false })
  });
});