define('ownersup-client/components/modal-base/component', ['exports', 'ember', 'ownersup-client/mixins/component-name'], function (exports, _ember, _ownersupClientMixinsComponentName) {
  var get = _ember['default'].get;
  var inject = _ember['default'].inject;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend(_ownersupClientMixinsComponentName['default'], {
    name: 'modal',
    flashMessages: inject.service(),

    _save: function _save(model, message) {
      var _this = this;

      var flashMessages = get(this, 'flashMessages');
      model.save().then(function () {
        flashMessages.success(message);
        _this.close();
      }, function (err) {
        _this.set('errors', err.errors);
      });
    }
  });
});