define('ownersup-client/components/comment-list-item/component', ['exports', 'ember', 'ownersup-client/mixins/confirmable', 'ownersup-client/mixins/component-attribute-types'], function (exports, _ember, _ownersupClientMixinsConfirmable, _ownersupClientMixinsComponentAttributeTypes) {
  var Component = _ember['default'].Component;
  var inject = _ember['default'].inject;
  exports['default'] = Component.extend(_ownersupClientMixinsConfirmable['default'], _ownersupClientMixinsComponentAttributeTypes['default'], {
    currentUser: inject.service('current-user'),

    classNames: ['comment-list-item'],
    classNameBindings: ['isConfirming'],

    attrTypes: {
      'delete': true
    },

    actions: {
      edit: function edit(comment) {
        comment.set('isEditing', true);
        this.sendAction('isEditing', true);
      },
      'delete': function _delete(comment) {
        this.sendAction('delete', comment);
      }
    }
  });
});