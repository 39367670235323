define('ownersup-client/user/serializer', ['exports', 'ownersup-client/application/serializer', 'ember'], function (exports, _ownersupClientApplicationSerializer, _ember) {
  var isEmpty = _ember['default'].isEmpty;
  exports['default'] = _ownersupClientApplicationSerializer['default'].extend({
    attrs: {
      linkedInUrl: 'linkedin_url'
    },
    serialize: function serialize(snapshot, options) {
      var _this = this;

      var json = this._super(snapshot, options);

      ['stripe_access_token', 'stripe_plan_id', 'current_password', 'password', 'password_confirmation'].forEach(function (key) {
        _this._deleteIfNull(json, key);
      });

      return json;
    },

    _deleteIfNull: function _deleteIfNull(json, key) {
      if (isEmpty(json[key])) {
        delete json[key];
      }
    }
  });
});