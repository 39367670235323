define('ownersup-client/components/user-form/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    actions: {
      submit: function submit() {
        var user = this.get('user');

        this.sendAction('action', user, 'account.profile', 'Profile successfully saved');
      }
    }
  });
});