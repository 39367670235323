define('ownersup-client/mixins/spinnerable', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  var computed = _ember['default'].computed;
  exports['default'] = Mixin.create({
    classNameBindings: ['isLoading:component-loading'],

    forceIsLoading: null,

    isLoading: computed('model.isLoading', 'forceIsLoading', function () {
      var forced = this.get('forceIsLoading');

      if (forced !== null) {
        return forced;
      }

      return !!this.get('model') && this.get('model.isLoading');
    }),

    start: function start() {
      this.set('forceIsLoading', true);
    },

    done: function done() {
      this.set('forceIsLoading', false);
    }
  });
});