define('ownersup-client/group/model', ['exports', 'ember', 'ember-data', 'ownersup-client/mixins/timestampable', 'ownersup-client/mixins/authorizable'], function (exports, _ember, _emberData, _ownersupClientMixinsTimestampable, _ownersupClientMixinsAuthorizable) {
  var computed = _ember['default'].computed;
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = Model.extend(_ownersupClientMixinsTimestampable['default'], _ownersupClientMixinsAuthorizable['default'], {
    name: attr('string'),
    groupMembershipsCount: attr('number'),

    groupMemberships: hasMany('groupMemberships', { async: true }),
    groupMembershipIds: computed.mapBy('groupMemberships', 'id'),
    liveGroupMemberships: computed('groupMemberships.@each.isDeleted', function () {
      return this.get('groupMemberships').filterBy('isDeleted', false);
    }),
    activeGroupMemberships: computed.filterBy('liveGroupMemberships', 'isActive'),
    invitedGroupMemberships: computed.filterBy('liveGroupMemberships', 'isInvited'),

    users: computed.mapBy('groupMemberships', 'user'),

    groupCategories: hasMany('group-categories', { async: false }),
    categories: computed.mapBy('groupCategories', 'category')
  });
});