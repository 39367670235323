define('ownersup-client/components/content-list/component', ['exports', 'ember', 'ember-group-by', 'ownersup-client/mixins/component-attribute-types'], function (exports, _ember, _emberGroupBy, _ownersupClientMixinsComponentAttributeTypes) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_ownersupClientMixinsComponentAttributeTypes['default'], {
    classNames: ['content-list'],

    attrTypes: {
      page: true,
      pageChanged: true,
      newComment: true,
      save: true,
      cancel: true
    },

    pagination: computed.oneWay('content.meta.pagination'),

    content: computed.oneWay('attrs.content'),
    listSort: ['createdAt:desc'],
    list: computed.sort('content', 'listSort'),
    groupedList: (0, _emberGroupBy['default'])('list', 'createdDate'),

    actions: {
      pageChanged: function pageChanged(current, previous) {
        this.sendAction('pageChanged', current, previous);
      },

      save: function save(record) {
        this.sendAction('save', record);
      },
      cancel: function cancel(record, path) {
        this.sendAction('cancel', record, path);
      },
      newComment: function newComment(comment) {
        this.sendAction('newComment', comment);
      }
    }
  });
});