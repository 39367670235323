define('ownersup-client/components/markdown-textarea/component', ['exports', 'ember', 'ownersup-client/mixins/component-name'], function (exports, _ember, _ownersupClientMixinsComponentName) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  exports['default'] = Component.extend(_ownersupClientMixinsComponentName['default'], {
    name: 'markdown-textarea',
    classNames: ['form-group'],
    classNameBindings: ['labelClass', 'previewVisible'],
    previewVisible: false,
    previewNotVisible: computed.not('previewVisible'),

    rows: 2,

    labelClass: computed('label', function () {
      if (this.get('class')) {
        return this.get('class').dasherize();
      }
      if (this.get('label')) {
        return this.get('label').dasherize();
      }
    }),

    actions: {
      togglePreview: function togglePreview() {
        var visible = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

        set(this, 'previewVisible', visible);
      }
    }
  });
});