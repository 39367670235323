define('ownersup-client/components/goal-form/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;
  exports['default'] = Component.extend({
    currentUser: inject.service(),
    flashMessages: inject.service(),

    classNames: ['goal-form'],
    actions: {
      submit: function submit() {
        var goal = this.get('goal');

        if (this._validGoal()) {
          this.sendAction('save', goal, 'progress.goals');
        }
      },

      submitAndNew: function submitAndNew() {
        var goal = this.get('goal');

        if (this._validGoal()) {
          this.sendAction('save', goal, 'goals.new');
        }
      },

      cancel: function cancel() {
        var goal = this.get('goal');

        this.sendAction('cancel', goal, 'progress.goals');
      },

      'delete': function _delete() {
        var goal = this.get('goal');

        this.sendAction('delete', goal, 'progress.goals');
      }
    },

    _validGoal: function _validGoal() {
      var flashMessages = get(this, 'flashMessages');

      var isValid = true;

      if (!this.get('goal.title')) {
        flashMessages.danger('Your goals success metric cannot be blank');
        isValid = false;
      }

      if (!this.get('goal.steps')) {
        flashMessages.danger('You must provide steps to complete');
        isValid = false;
      }

      if (!this.get('goal.hasCategory')) {
        flashMessages.danger('You must select a category for this goal');
        isValid = false;
      }

      if (!this.get('goal.dueDate')) {
        flashMessages.danger('You must set a goal due date');
        isValid = false;
      }

      return isValid;
    }
  });
});