define('ownersup-client/comment/model', ['exports', 'ember-data', 'ownersup-client/mixins/timestampable', 'ownersup-client/mixins/authorizable'], function (exports, _emberData, _ownersupClientMixinsTimestampable, _ownersupClientMixinsAuthorizable) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend(_ownersupClientMixinsTimestampable['default'], _ownersupClientMixinsAuthorizable['default'], {
    body: attr('string'),
    commentable: belongsTo('commentable', { polymorphic: true, async: true }),
    groupMembership: belongsTo('group-membership', { async: true }),
    isEditing: attr('boolean', { defaultValue: false })
  });
});