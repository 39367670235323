define('ownersup-client/components/modals/modal-notification-time-settings/component', ['exports', 'ember', 'moment', 'ownersup-client/components/modal-base/component'], function (exports, _ember, _moment, _ownersupClientComponentsModalBaseComponent) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = _ownersupClientComponentsModalBaseComponent['default'].extend({
    groupMembership: computed.alias('modalContext'),
    timeZone: computed.readOnly('groupMembership.user.timeZone'),

    availableTimes: computed(function () {
      var times = [];
      var periods = ['AM', 'PM'];
      var hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
      var minutes = ['00', '30'];

      periods.forEach(function (period) {
        hours.forEach(function (hour) {
          minutes.forEach(function (minute) {
            times.push(hour + ':' + minute + ' ' + period);
          });
        });
      });

      return times;
    }),

    actions: {
      updateReminderTime: function updateReminderTime(key, selection) {
        var timeZone = get(this, 'timeZone');
        var value = _moment['default'].tz(selection, 'h:mm A', timeZone).tz('UTC');

        set(this, 'groupMembership.' + key, value);
      },

      handleSave: function handleSave() {
        var groupMembership = get(this, 'groupMembership');

        this._save(groupMembership, 'Success! Your reminder time has been updated');
      }
    }
  });
});