define('ownersup-client/progress/tallyups/route', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var Route = _ember['default'].Route;
  var RSVP = _ember['default'].RSVP;
  var inject = _ember['default'].inject;
  exports['default'] = Route.extend({
    currentUser: inject.service(),

    model: function model() {
      var params = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var groupMembership = this.get('currentUser.activeGroupMembership');

      params.group_membership_id = [groupMembership.id];
      params.from = (0, _moment['default'])().subtract(1, 'year').startOf('month').format('YYYY-MM-DD');
      params.page_size = 500;

      return RSVP.hash({
        tallyups: this.store.query('tallyup', params),
        stats: groupMembership.customAction('stats/tallyups').then(function (response) {
          return response.stats.tallyup_stats;
        })
      });
    }
  });
});