define('ownersup-client/group/serializer', ['exports', 'ownersup-client/application/serializer'], function (exports, _ownersupClientApplicationSerializer) {
  exports['default'] = _ownersupClientApplicationSerializer['default'].extend({
    // serializeHasMany(snapshot, json, relationship) {
    //   var key = relationship.key;
    //   if (key === 'groupCategories') {
    //     this.serializeGroupCategoriesIntoCategoryIds(json, snapshot);
    //   } else {
    //     this._super(...arguments);
    //   }
    // },

    serializeGroupCategoriesIntoCategoryIds: function serializeGroupCategoriesIntoCategoryIds(json, groupCategories) {
      var category_ids = groupCategories.hasMany('groupCategories').map(function (groupCategory) {
        return groupCategory.belongsTo('category', { id: true });
      });
      json.category_ids = category_ids;
    }
  });
});