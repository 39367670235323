define('ownersup-client/components/modals/modal-trial-expired/component', ['exports', 'ownersup-client/components/modal-base/component'], function (exports, _ownersupClientComponentsModalBaseComponent) {
  exports['default'] = _ownersupClientComponentsModalBaseComponent['default'].extend({
    actions: {
      processStripeToken: function processStripeToken(token) {
        var context = this.get('modalContext');
        return context.send('processStripeToken', token);
      }
    }
  });
});
// import Ember from 'ember';