define('ownersup-client/admin/groups/index/controller', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var _get = _ember['default'].get;
  var emberArray = _ember['default'].A;
  var Controller = _ember['default'].Controller;

  // import macros from 'ember-macaroni';
  // const { filterFromCollectionByKey } = macros;

  exports['default'] = Controller.extend({
    ids: [],
    filteredResults: computed('model.@each.id', {
      get: function get() {
        var values = _get(this, 'ids');
        return emberArray(_get(this, 'model')).filter(function (item) {
          return emberArray(values).includes(parseInt(item.id));
        });
      }
    }),
    results: computed('model', 'ids', function () {
      var key = _get(this, 'ids.length') ? 'filteredResults' : 'model';

      return _get(this, key);
    }),

    actions: {
      handleResults: function handleResults(results) {
        var groups = results.groups;

        this.store.pushPayload({ groups: groups });

        this.set('ids', groups.mapBy('id'));
      }
    }
  });
});