define('ownersup-client/application/route', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin', 'ownersup-client/mixins/rollback-record', 'ownersup-client/mixins/flash-messageable', 'ownersup-client/mixins/trackable-record'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin, _ownersupClientMixinsRollbackRecord, _ownersupClientMixinsFlashMessageable, _ownersupClientMixinsTrackableRecord) {
  var Route = _ember['default'].Route;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var inject = _ember['default'].inject;
  var testing = _ember['default'].testing;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], _ownersupClientMixinsFlashMessageable['default'], _ownersupClientMixinsRollbackRecord['default'], _ownersupClientMixinsTrackableRecord['default'], {
    session: inject.service('session'),
    currentUser: inject.service('current-user'),
    metrics: inject.service(),
    flashMessages: inject.service(),

    beforeModel: function beforeModel(transition) {
      this._super(transition);

      if (this.get('session.isAuthenticated')) {
        return this._hydrateCurrentUser();
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (this.get('session.isAuthenticated')) {
        controller._verifyUserStatus();
      }
    },

    actions: {
      deleteRecord: function deleteRecord(record) {
        var path = arguments.length <= 1 || arguments[1] === undefined ? 'index' : arguments[1];
        var message = arguments.length <= 2 || arguments[2] === undefined ? 'Successfully deleted' : arguments[2];

        this._deleteRecord(record, path, message);
      },

      rollbackRecord: function rollbackRecord(record) {
        var path = arguments.length <= 1 || arguments[1] === undefined ? 'index' : arguments[1];

        this._rollbackRecord(record, path);
      },

      // IDEA: Swap arguemtns to record, message, path, arguments
      // This way we can redirect to a specific model
      saveRecord: function saveRecord(record) {
        var path = arguments.length <= 1 || arguments[1] === undefined ? 'index' : arguments[1];
        var message = arguments.length <= 2 || arguments[2] === undefined ? 'Successfully saved!' : arguments[2];

        this._saveRecord(record, path, message);
      },

      setCurrentGroupMembership: function setCurrentGroupMembership(groupMembership) {
        var _this = this;

        this.get('currentUser').set('activeGroupMembershipId', groupMembership.get('id')).then(function () {
          _this.refresh();
        });
      },

      newComment: function newComment(comment) {
        this.store.createRecord('comment', comment);
      },

      showModal: function showModal(modalDialogName, modalContext) {
        this._showModal(modalDialogName, modalContext);
      }
    },

    identifyUser: function identifyUser() {
      if (this.get('session.isAuthenticated')) {
        var user = this.get('currentUser.user');
        var userProperties = user.getProperties('id', 'email', 'companyName', 'role', 'stripePlanId', 'isCreditCardOnFile', 'isTrialing');
        userProperties.distinctId = user.get('id');
        userProperties.name = user.get('fullName');

        set(this, 'metrics.context', userProperties);
        get(this, 'metrics').identify(userProperties);

        if (typeof Bugsnag !== 'undefined') {
          Bugsnag.user = userProperties;
        }
      }
    },

    _hydrateCurrentUser: function _hydrateCurrentUser() {
      var _this2 = this;

      return this.get('currentUser.user').then(function () {
        _this2.identifyUser();
      });
    },

    sessionAuthenticated: function sessionAuthenticated() {
      var _this3 = this;

      get(this, 'flashMessages').success('Welcome! You are now logged in.');

      var attemptedTransition = this.get('session.attemptedTransition');

      this._hydrateCurrentUser().then(function () {
        if (attemptedTransition) {
          attemptedTransition.retry();
          _this3.set('session.attemptedTransition', null);
        } else {
          _this3.transitionTo('index');
        }
      });
    },

    sessionInvalidated: function sessionInvalidated() {
      this.get('session.store').clear();
      if (!testing) {
        window.location.replace('/');
      }
    },

    _deleteRecord: function _deleteRecord(record, path, message) {
      var _this4 = this;

      record.destroyRecord().then(function () {
        _this4.trackModelEvent(record, 'Deleted');

        if (path) {
          _this4.transitionTo(path);
        }

        _this4._displaySuccessMessage(message);
      })['catch'](function (err) {
        _this4._displayErrors(err);
      });
    },

    _showModal: function _showModal(modalDialogName, modalContext) {
      this.controllerFor('application').send('showModal', modalDialogName, modalContext);
    },

    _saveRecord: function _saveRecord(record, path, message) {
      var _this5 = this;

      this.trackModelEvent(record);

      record.save().then(function () {
        if (path) {
          if (path === _this5.controller.currentPath) {
            _this5.refresh();
          } else {
            _this5.transitionTo(path);
          }
        }
        _this5._displaySuccessMessage(message);
      }, function (err) {
        _this5._displayErrors(err);
      });
    }
  });
});