define('ownersup-client/components/scope-bar/component', ['exports', 'ember', 'ownersup-client/mixins/component-name'], function (exports, _ember, _ownersupClientMixinsComponentName) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = Component.extend(_ownersupClientMixinsComponentName['default'], {
    type: 'scope',
    label: false,
    classNameBindings: ['labelDasherized'],

    name: computed('type', function () {
      return get(this, 'type') + '-bar';
    }),

    labelDasherized: computed('label', function () {
      var label = get(this, 'label');
      return label ? label.dasherize() : false;
    })
  });
});