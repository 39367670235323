define('ownersup-client/components/page-header/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = Component.extend({
    tagName: 'header',
    classNames: ['page-header'],
    classNameBindings: ['media.classNames'],

    session: inject.service('session'),
    currentUser: inject.service('current-user'),

    shouldShowMenu: computed.and('currentUser.user.isFulfilled', 'session.isAuthenticated'),
    groupListVisible: false,
    activeGroup: computed.reads('currentUser.activeGroup'),
    multipleGroups: computed.gt('currentUser.groupMembershipIds.length', 1),
    otherGroupMemberships: computed.filter('currentUser.user.groupMemberships', function (groupMembership) {
      var activeGroupMembershipId = this.get('currentUser.activeGroupMembershipId').toString();
      return groupMembership.get('id') !== activeGroupMembershipId;
    }),

    actions: {
      logout: function logout() {
        this.sendAction('logout');
      },

      toggleGroupMenu: function toggleGroupMenu() {
        this.toggleProperty('groupListVisible');
      },

      setCurrentGroupMembership: function setCurrentGroupMembership(groupMembership) {
        this.get('currentUser').set('activeGroupMembershipId', groupMembership.get('id'));
        this.set('groupListVisible', false);
      }
    }
  });
});