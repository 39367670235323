define('ownersup-client/report/serializer', ['exports', 'ownersup-client/application/serializer'], function (exports, _ownersupClientApplicationSerializer) {
  exports['default'] = _ownersupClientApplicationSerializer['default'].extend({
    attrs: {
      reportActivitiesCount: { serialize: false },
      canUpdate: { serialize: false },
      canDestroy: { serialize: false },
      isOwner: { serialize: false },
      commentsCount: { serialize: false },
      updatedAt: { serialize: false },
      type: { serialize: false }
    }
  });
});