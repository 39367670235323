define('ownersup-client/group/goals/index/route', ['exports', 'ember', 'ownersup-client/mixins/paginated-route', 'ownersup-client/mixins/group-membership-content-route'], function (exports, _ember, _ownersupClientMixinsPaginatedRoute, _ownersupClientMixinsGroupMembershipContentRoute) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend(_ownersupClientMixinsPaginatedRoute['default'], _ownersupClientMixinsGroupMembershipContentRoute['default'], {
    templateName: 'group.index',
    model: function model(params) {
      params.group_membership_id = this._getRouteGroupGroupMemberhipIds();

      return this.store.query('goal', params);
    },

    afterModel: function afterModel(model) {
      this._super(model);
      this.store.query('comment', { commentable_id: model.mapBy('id') });
    }
  });
});