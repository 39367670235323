define('ownersup-client/instance-initializers/bugsnag', ['exports', 'ember', 'ownersup-client/config/environment', 'ember-cli-bugsnag/utils/errors', 'ownersup-client/utils/bugsnag', 'bugsnag'], function (exports, _ember, _ownersupClientConfigEnvironment, _emberCliBugsnagUtilsErrors, _ownersupClientUtilsBugsnag, _bugsnag) {
  exports['default'] = {
    name: 'bugsnag-error-service',

    initialize: function initialize(instance) {

      if (_bugsnag['default'].apiKey === undefined) {
        return;
      }

      var currentEnv = _ownersupClientConfigEnvironment['default'].environment;
      var bugsnagConfig = _ownersupClientConfigEnvironment['default'].bugsnag || {};
      var releaseStage = bugsnagConfig.releaseStage || currentEnv;

      if (currentEnv !== 'test' && _bugsnag['default'].notifyReleaseStages.indexOf(releaseStage) !== -1) {
        (function () {
          var owner = instance.lookup ? instance : instance.container;
          var router = owner.lookup('router:main');

          _ember['default'].onerror = function (error) {
            _bugsnag['default'].context = (0, _emberCliBugsnagUtilsErrors.getContext)(router);
            var metaData = (0, _ownersupClientUtilsBugsnag.getMetaData)(error, owner);
            _bugsnag['default'].notifyException(error, null, metaData);
            console.error(error.stack);
          };

          var originalDidTransition = router.didTransition || _ember['default'].K;
          router.didTransition = function () {
            _bugsnag['default'].refresh();
            return originalDidTransition.apply(this, arguments);
          };
        })();
      }
    }
  };
});