define('ownersup-client/mixins/component-attribute-types', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  var assert = _ember['default'].assert;
  exports['default'] = Mixin.create({
    didReceiveAttrs: function didReceiveAttrs() {
      this._validAttrs();
    },

    _validAttrs: function _validAttrs() {
      var attrs = this.attrs;
      var attrTypes = this.attrTypes;

      if (attrTypes) {
        assert('Component ' + this.toString() + ' did not receive any attributes despite having attributes requirements', attrs);
      }

      for (var key in attrTypes) {
        if (attrTypes[key] === true) {
          assert('Component ' + this.toString() + ' requires the attribute ' + key, Object.keys(attrs).includes(key));
        }
      }

      return true;
    }
  });
});