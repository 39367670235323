define('ownersup-client/admin/groups/route', ['exports', 'ember', 'ownersup-client/mixins/flash-messageable', 'ownersup-client/mixins/trackable-record'], function (exports, _ember, _ownersupClientMixinsFlashMessageable, _ownersupClientMixinsTrackableRecord) {
  var Route = _ember['default'].Route;
  var inject = _ember['default'].inject;
  exports['default'] = Route.extend(_ownersupClientMixinsFlashMessageable['default'], _ownersupClientMixinsTrackableRecord['default'], {
    metrics: inject.service(),
    actions: {
      saveRecord: function saveRecord(group) {
        var _this = this;

        // If a user or membership is being saved, pass it off
        if (group.constructor.modelName !== 'group') {
          return true;
        }

        this.trackModelEvent(group);

        return group.save()
        // .then(() => {
        //   return group.get('groupCategories').save();
        // })
        .then(function () {
          _this._displaySuccessMessage('Group Successfully Created');
          _this.transitionTo('admin.groups.group', group);
        })['catch'](function (err) {
          _this._displayErrors(err);
        });
      }
    }
  });
});