define('ownersup-client/components/modal-current-password/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var inject = _ember['default'].inject;
  exports['default'] = Component.extend({
    currentUser: inject.service(),
    flashMessages: inject.service(),

    actions: {
      confirmUpdate: function confirmUpdate() {
        var token = this.get('modalContext.token');
        var currentPassword = this.get('currentPassword');

        if (!currentPassword) {
          this.set('error', 'Hey there. Make sure you fill out your current password');
          return false;
        }

        return this._saveUser(currentPassword, token);
      }
    },

    _saveUser: function _saveUser(currentPassword, token) {
      var _this = this;

      var flash = this.get('flashMessages');

      this.get('currentUser.user').then(function (user) {
        user.setProperties({ currentPassword: currentPassword, token: token });

        user.save().then(function () {
          user.reload();
          flash.success('Sweet! Your account has been updated');
          _this.close();
        }, function (err) {
          _this.set('error', err.errors[0].detail);
        });
      });
    }
  });
});