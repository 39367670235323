define('ownersup-client/mixins/expandable', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  var computed = _ember['default'].computed;
  exports['default'] = Mixin.create({
    classNames: ['card'],
    classNameBindings: ['isExpanded:expanded:collapsed', 'lockExpanded:locked'],

    expanded: false,
    locked: false,
    isExpanded: computed.or('expanded', 'locked'),
    isCollapsable: computed('expanded', 'locked', function () {
      return this.get('expanded') && !this.get('locked');
    }),

    actions: {
      toggle: function toggle() {
        this._toggle();
      },
      expand: function expand() {
        this._expand();
      },
      collapse: function collapse() {
        this._collapse();
      }
    },

    _toggle: function _toggle() {
      if (!this.get('lockExpanded')) {
        this.set('expanded', !this.get('expanded'));
      }
    },
    _expand: function _expand() {
      if (this.get('isExpanded') === false) {
        this.set('expanded', true);
      }
    },
    _collapse: function _collapse() {
      if (this.get('isCollapsable')) {
        this.set('expanded', false);
      }
    }
  });
});