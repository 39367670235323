define('ownersup-client/components/content-table/component', ['exports', 'ember', 'ownersup-client/mixins/component-attribute-types', 'ownersup-client/mixins/component-name'], function (exports, _ember, _ownersupClientMixinsComponentAttributeTypes, _ownersupClientMixinsComponentName) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_ownersupClientMixinsComponentAttributeTypes['default'], _ownersupClientMixinsComponentName['default'], {
    name: 'content-table',

    init: function init() {
      this._super.apply(this, arguments);
      var infinityLoad = this.attrs.infinityLoad;

      if (infinityLoad) {
        this.infinityLoad = infinityLoad;
      } else {
        this.infinityLoad = false;
      }
    },

    attrTypes: {
      content: true
    },

    // contentType: computed.reads('content.firstObject.constructor.modelName'),
    tableName: computed(function () {
      return (this.get('content.firstObject.constructor.modelName') || 'content') + '-table';
    }),

    currentPage: computed.reads('content.meta.pagination.current_page'),
    totalPages: computed.reads('content.meta.pagination.total_pages'),
    totalCount: computed.reads('content.meta.pagination.total_count'),

    content: computed.reads('attrs.content'),
    listSort: ['createdAt:desc'],
    list: computed.sort('content', 'listSort'),

    actions: {
      infinityLoad: function infinityLoad(infinityModel) {
        this.sendAction('infinityLoad', infinityModel);
      },
      save: function save(record) {
        this.sendAction('save', record);
      },
      cancel: function cancel(record, path) {
        this.sendAction('cancel', record, path);
      },
      newComment: function newComment(comment) {
        this.sendAction('newComment', comment);
      }
    }
  });
});