define('ownersup-client/components/calendar-day/component', ['exports', 'ember', 'ember-moment/computeds/format', 'moment'], function (exports, _ember, _emberMomentComputedsFormat, _moment) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: 'a',
    classNames: ['calendar-day', 'day'],
    classNameBindings: ['active', 'dayContext'],

    dayNumber: (0, _emberMomentComputedsFormat['default'])('date', 'D'),
    dayName: (0, _emberMomentComputedsFormat['default'])('date', 'dd'),

    click: function click() {
      var date = (0, _moment['default'])(this.get('date'));
      var today = new Date();

      if (!date.isAfter(today, 'day')) {
        this.sendAction('action', (0, _moment['default'])(this.get('date')).format('YYYY-MM-DD'));
      }
    },

    active: computed('date', 'activeDate', function () {
      var date = (0, _moment['default'])(this.get('date'));
      var active = (0, _moment['default'])(this.get('activeDate', 'YYYY-MM-DD'));

      return date.isSame(active, 'day');
    }),

    dayContext: computed('date', function () {
      var date = (0, _moment['default'])(this.get('date'));
      var today = new Date();

      if (date.isAfter(today, 'day')) {
        return 'is-future';
      } else if (date.isBefore(today, 'day')) {
        return 'is-past';
      } else {
        return 'today';
      }
    })
  });
});