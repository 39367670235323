define('ownersup-client/components/tab-bar/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['tab-bar-container'],
    showAddMenu: false,

    actions: {
      toggleAddMenu: function toggleAddMenu() {
        this.toggleProperty('showAddMenu');
      }
    }
  });
});