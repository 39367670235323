define('ownersup-client/helpers/format-money', ['exports', 'ember'], function (exports, _ember) {
  exports.formatMoney = formatMoney;
  var Helper = _ember['default'].Helper;

  function formatMoney(params /*, hash*/) {
    var number = '' + parseInt(params);

    number = number.replace(/\d(?=(\d{3})+$)/g, '$&,');

    return ('<span class="symbol">$</span>' + number).htmlSafe();
  }

  exports['default'] = Helper.helper(formatMoney);
});