define('ownersup-client/report-activity/model', ['exports', 'ember', 'ember-data', 'ownersup-client/mixins/timestampable', 'ownersup-client/mixins/categorizable'], function (exports, _ember, _emberData, _ownersupClientMixinsTimestampable, _ownersupClientMixinsCategorizable) {
  var computed = _ember['default'].computed;
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend(_ownersupClientMixinsTimestampable['default'], _ownersupClientMixinsCategorizable['default'], {
    title: attr('string'),
    description: attr('string'),
    type: attr('string'),

    report: belongsTo('report', { async: true }),
    reportId: attr('string'),
    category: belongsTo('category', { async: true }),

    notEmpty: computed.notEmpty('description')
  });
});