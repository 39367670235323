define('ownersup-client/users/invitation/accept/route', ['exports', 'ember', 'moment', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'ownersup-client/mixins/flash-messageable'], function (exports, _ember, _moment, _emberSimpleAuthMixinsUnauthenticatedRouteMixin, _ownersupClientMixinsFlashMessageable) {
  var Route = _ember['default'].Route;
  var Logger = _ember['default'].Logger;
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;
  var run = _ember['default'].run;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], _ownersupClientMixinsFlashMessageable['default'], {
    metrics: inject.service(),
    flashMessages: inject.service(),

    model: function model(params) {
      var _this = this;

      var user = this.store.createRecord('user', params);
      var invitation_token = params.invitation_token;
      var stripe_access_token = params.stripe_access_token;

      return RSVP.hash({
        invitation: user.customAction('invitation/accept', 'GET', { invitation_token: invitation_token }).then(function (_ref) {
          var invitation = _ref.invitation;

          invitation.invitation_token = invitation_token;
          invitation.stripe_access_token = stripe_access_token;
          _this.store.pushPayload({ 'user': invitation });
          user.destroyRecord();
          return invitation;
        })
      });
    },

    afterModel: function afterModel(model) {
      var user = this.store.peekRecord('user', model.invitation.id);

      this._guessUsersTimezone(user);

      model.user = user;
    },

    actions: {
      submit: function submit() {
        var user = get(this, 'controller.model.user').serialize();

        if (this._validateForm(user)) {
          return this._submitInvitationUpdate(user);
        }

        return false;
      }
    },

    _validateForm: function _validateForm(data) {
      var flashMessages = get(this, 'flashMessages');

      if (!data.password) {
        flashMessages.danger('Come on now, set a password!');
        return false;
      }

      if (data.password.length < 8) {
        flashMessages.danger('Your password is too short. It must be at least 8 characters');
        return false;
      }

      if (data.password !== data.password_confirmation) {
        flashMessages.danger('Your passwords didn\'t match!');
        return false;
      }

      if (!data.first_name) {
        flashMessages.danger('It\'s rude not to introduce yourself! Whats your first name?');
        return false;
      }

      if (!data.last_name) {
        flashMessages.danger('We need to know your last name for importnat businessy things');
        return false;
      }

      if (!data.company_name) {
        flashMessages.danger('Please let us know the name of your company');
        return false;
      }

      if (!data.bio) {
        flashMessages.danger('Please fill out your bio');
        return false;
      }

      if (this.controller.get('shouldCollectPaymentInfo') && !data.stripe_access_token) {
        flashMessages.danger('Please add a credit card by clicking the appropriately named \'Add credit card\' button');
        return false;
      }

      if (!data.agreed) {
        flashMessages.danger('We highly recommend reading & agreeing to our Terms of Service (Click the last little check box!)');
        return false;
      }

      return true;
    },

    _submitInvitationUpdate: function _submitInvitationUpdate(data) {
      var _this2 = this;

      var user = arguments.length <= 1 || arguments[1] === undefined ? this.store.createRecord('user') : arguments[1];

      var flashMessages = get(this, 'flashMessages');

      var postData = { api_v1_user: data };

      this.controller.set('saving', true);

      return user.customAction('invitation', 'PUT', postData).then(function () {
        run(function () {
          flashMessages.success('Invitation accepted! Please log in');
          get(_this2, 'metrics').trackEvent({
            event: 'Accept Invitation'
          });

          _this2.store.unloadAll();
          return _this2.transitionTo('/login');
        });
      }, function (error) {
        run(function () {
          _this2._displayErrors(error);
          Logger.error(error);
          return RSVP.reject(error);
        });
      })['finally'](function () {
        _this2.controller.set('saving', false);
      });
    },

    _guessUsersTimezone: function _guessUsersTimezone(user) {
      user.set('timeZone', _moment['default'].tz.guess());
    }
  });
});