define('ownersup-client/components/eyebrow-menu/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var inject = _ember['default'].inject;
  exports['default'] = Component.extend({
    session: inject.service('session'),
    currentUser: inject.service('current-user'),

    classNames: ['eyebrow-menu'],
    userPopoverVisible: false,

    actions: {
      toggleUserPopver: function toggleUserPopver() {
        this.toggleProperty('userPopoverVisible');
      },

      logout: function logout() {
        this.get('session').invalidate();
      }
    }
  });
});