define('ownersup-client/helpers/number-to-k', ['exports', 'ember'], function (exports, _ember) {
  exports.numberToK = numberToK;
  var Helper = _ember['default'].Helper;

  function lastDigit(number) {
    return number.charAt(number.length - 1);
  }

  function trimNumber(number) {
    number = number.slice(0, -2); // 12,300 -> 123

    var trimmerNumber = undefined;

    if (lastDigit(number) === '0') {
      trimmerNumber = number.slice(0, -1); // 120 -> 12
    } else {
        trimmerNumber = [number.slice(0, -1), '.', number.slice(-1)].join(''); // 123 -> 12.3
      }

    return trimmerNumber;
  }

  function appendK(number, html) {
    if (html) {
      return number + '<span class="k">K</span>';
    } else {
      return number + 'K';
    }
  }

  function prepend$(number, html) {
    if (html) {
      return ('<span class="symbol">$</span>' + number).htmlSafe();
    } else {
      return '$' + number;
    }
  }

  function numberToK(params) {
    var _ref = arguments.length <= 1 || arguments[1] === undefined ? { html: true } : arguments[1];

    var html = _ref.html;

    var number = '' + parseInt(params);

    if (number.length > 3) {
      number = trimNumber(number);
      number = appendK(number, html);
    }

    return prepend$(number, html);
  }

  exports['default'] = Helper.helper(numberToK);
});