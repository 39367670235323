define('ownersup-client/index/route', ['exports', 'ember', 'moment', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _moment, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var Route = _ember['default'].Route;
  var RSVP = _ember['default'].RSVP;
  var inject = _ember['default'].inject;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    queryParams: {
      activeDate: {
        replace: true
      }
    },
    currentUser: inject.service('current-user'),
    session: inject.service(),

    model: function model() {
      return RSVP.hash({
        goals: this.store.query('goal', {
          group_membership_id: [this.get('currentUser.activeGroupMembershipId')],
          status: 'active'
        }),
        reports: this.store.query('report', {
          group_membership_id: this.get('currentUser.activeGroup.groupMembershipIds')
        })
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties(model);

      RSVP.hash({
        messages: this.store.query('message', {
          group_membership_id: this.get('currentUser.activeGroup.groupMembershipIds')
        }),
        tallyups: this.store.query('tallyup', {
          from: (0, _moment['default'])().date(1).format('YYYY-MM-DD'),
          group_membership_id: [this.get('currentUser.activeGroupMembershipId')]
        })
      }).then(function (results) {
        controller.setProperties({
          'model.messages': results.messages
        });
      });
    },

    actions: {
      setActiveDate: function setActiveDate(date) {
        this.controller.set('activeDate', date);
      }
    }
  });
});