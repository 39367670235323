define('ownersup-client/utc-time/transform', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  var Transform = _emberData['default'].Transform;
  exports['default'] = Transform.extend({
    deserialize: function deserialize(serialized) {
      if (!serialized) {
        return null;
      }

      var time = this._prepareString(serialized);
      return _moment['default'].utc(time, 'HH:mm:ss');
    },

    serialize: function serialize(deserialized) {
      return deserialized ? deserialized.format('HH:mm:ss') : null;
    },

    _prepareString: function _prepareString(time) {
      var bigT = time.indexOf('T');
      return bigT && time.length > 8 ? time.substring(bigT + 1) : time;
    }
  });
});